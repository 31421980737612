import React, { useEffect, useState } from 'react'
import './modal.scss'
import ModalTileCreate from './ModalTileCreate'
import ModalTileType from './ModalTileType'
import { ReactComponent as Edit } from '../../../../assets/icons/editPen.svg'
import { hideLoaderAction, showLoaderAction, showSimpleModalAction } from '../../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { deleteSimpleTile, fetchTile, patchTileMenu } from '../../../../crud/spaces/spaces'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import { toNull } from '../../../../helpers/formUtils'
import { CustomSwitcher } from '../../../../components/CustomSwitcher/CustomSwitcher'

const ModalTile = ({ spaceData, setSpaceData, showcase }) => {
  const [modalCreate, setModalCreate] = useState(false)
  const [modalTile, setModalTile] = useState(false)
  const [tileInfo, setTileInfo] = useState(null)
  const [typeTile, setTypeTile] = useState('')
  const [tileLinktype, setTileLinktype] = useState('')
  const [current, setCurrent] = useState(null)
  const [isNotSave, setIsNotSave] = useState(false)
  const [idForUpdate, setIdFotUpdate] = useState(undefined)
  const [tileSetting, setTileSetting] = useState(null)
  const [isRemoveCurrent, setIsRemoveCurrent] = useState(false)
  const [urlMedia, setUrlMedia] = useState(null)
  const dispatch = useDispatch()
  const color = useSelector(state => state.profile.color);

  const fetchTiles = () => {
    dispatch(showLoaderAction())
    fetchTile(spaceData?.id)
      .then(res => {
        let data = res?.data
        setTileInfo(data)
        setTileSetting(data?.tileSettings)
      })
      .catch((err) => {
        const errors = err?.response?.data
        const { error, message, statusCode } = errors
        dispatch(showSimpleModalAction({ title: error, text: message }))
      })
      .finally(() => {
        dispatch(hideLoaderAction())
        setIsRemoveCurrent(false)
        setUrlMedia(null)
      })
  }
  useEffect(() => {
    fetchTiles()
  }, [])

  const changeSwitch = (e) => {
    dispatch(showLoaderAction())
    let event = e.target.checked
    setTileSetting(prev => ({ ...prev, help: event }))
    let sender = { ...tileSetting, help: event }
    patchTileMenu(
      location.pathname.split('/')[3],
      sender
    )
      .then(res => {
        dispatch(hideLoaderAction())
        setSpaceData(prev => ({
          ...prev,
          tileSettings: { ...res.data },
        }))
      })
  }
  const onChangeCreateModalDropdown = (e) => {
    if (!e) return
    setTileLinktype(e)
    setCurrent(null)
    setModalCreate(false)
    setModalTile(true)
  }

  const onChangeModalDropdown = (e) => {
    if (!e) return
    setTileLinktype(e)
    setModalCreate(false)
    setCurrent(null)
    setIsRemoveCurrent(true)
    setUrlMedia(null)
  }
  const getBg = (tile) => {
    if (tile?.linktype === 'image') {
      return `center / cover no-repeat url(${tile?.url})`
    }
    if (tile?.backgroundimg) {
      return `center / cover no-repeat url(${tile?.backgroundimg})`
    }
    if (tile?.payload?.backgroundColor) {
      return tile?.payload?.backgroundColor
    } else {
      return
    }
  }
  const getBgHero = (tile) => {
    if (tile?.heroType === 'image') {
      return `center / cover no-repeat url(${tile?.imageUrl})`
    }
    if (tile?.backgroundImgUrl) {
      return `center / cover no-repeat url(${tile?.backgroundImgUrl})`
    }
    if (tile?.payload?.backgroundColor) {
      return tile?.payload?.backgroundColor
    }
  }
  const getHeightHero = () => {
    let getHeight = spaceData?.tileSettings?.heroHeight
    if (getHeight) {
      return getHeight + 'px'
    } else {
      return '150px'
    }
  }
  const getTemplate = () => {
    let getColum = spaceData?.tileSettings?.layoutColumns
    if (Number.isInteger(getColum)) {
      return `repeat(${getColum},1fr)`
    } else {
      return 'repeat(3,1fr)'
    }
  }
  const getHeighForItem = () => {
    let getHeight = spaceData?.tileSettings?.tileHeight
    if (getHeight) {
      return getHeight + 'px'
    } else {
      return '60px'
    }
  }

  return (
    <>
      {isNotSave && <ConfirmModal
        title="Delete tile"
        cancelBtn="No"
        submitBtn="Yes"
        submit={() => {
          if (current) {
            dispatch(showLoaderAction())
            deleteSimpleTile(spaceData.id, current)
              .then((res) => {
                debugger
                fetchTiles()
              })
              .catch((err) => {
                const errors = err?.response?.data
                const { error, message, statusCode } = errors
                dispatch(showSimpleModalAction({ title: error, text: message }))
              })
              .finally(() => {
                setCurrent(null)
                setIsNotSave(false)
                dispatch(hideLoaderAction())
              })
          }
        }}
        close={() => {
          setIsNotSave(false)
          setCurrent(null)
        }}
        text="Do you really want to remove this tile"
      />}
      {modalCreate &&
        <ModalTileCreate
          close={() => {
            setCurrent(null)
            setModalCreate(false)
            setTypeTile('')
            setTileLinktype('')
          }}
          onChange={onChangeCreateModalDropdown}
          typeTile={typeTile}
        />
      }
      {
        modalTile && <ModalTileType
          spaceData={spaceData}
          showcase={showcase}
          close={() => {
            setCurrent(null)
            setModalTile(false)
            setTypeTile('')
            setTileLinktype('')
            setIdFotUpdate(undefined)
            setIsRemoveCurrent(false)
            setUrlMedia(null)
          }}
          isCurrent={isRemoveCurrent}
          current={current}
          typeTile={typeTile}
          typeLink={tileLinktype}
          setTileLinktype={onChangeModalDropdown}
          updateDate={fetchTiles}
          currId={idForUpdate}
          urlMedia={urlMedia}
        />
      }
      <div className='tile-modal'>
        {spaceData?.tileSettings?.showHero && <div
          style={{ background: getBgHero(tileInfo?.hero), height: getHeightHero() }}
          className='tile-modal__hero'>
          {/*<div className='tile-modal__delete'>✖</div>*/}
          <span className='tile-modal__head-link'>{tileInfo?.hero?.heroType}</span>
          <button
            onClick={(e) => {
              e.preventDefault()
              setTypeTile('hero')
              if (tileInfo?.hero == null) {
                setModalCreate(true)
                setTileLinktype('')
              } else {
                setTileLinktype({
                  name: `${tileInfo?.hero?.heroType.charAt(0).toUpperCase() + tileInfo?.hero?.heroType.slice(1)}`,
                  value: tileInfo?.hero?.heroType
                })
                setModalTile(true)
                setCurrent(tileInfo?.hero)
                setUrlMedia(tileInfo?.hero?.imageUrl || tileInfo?.hero?.videoUrl || tileInfo?.hero?.audioUrl)
              }
            }}

            className='tile-modal__btn tile-modal__hero__btn'>
            <Edit fill={color} style={{ width: '26px' }} />
          </button>
          {tileInfo?.hero?.payload?.title && <span
            style={{ color: tileInfo?.hero?.payload?.titleColor }}
            className='tile-modal__main-title'>
            {tileInfo?.hero?.payload?.title}
          </span>}
          {tileInfo?.hero?.heroType === 'text' && <span
            style={{ color: tileInfo?.hero?.payload?.titleColor }}
            className='tile-modal__main-title tile-modal__main-title--text'
            dangerouslySetInnerHTML={{ __html: tileInfo?.hero?.payload?.text }}
          >
          </span>}
        </div>}
        <div className='tile-modal__tile' style={{ gridTemplateColumns: getTemplate() }}>
          {tileInfo?.tiles?.map((tile, index) => {
            return (
              <div
                key={index}
                onClick={(e) => {
                  e.stopPropagation()
                  setTileLinktype({
                    name: `${tile?.linktype.charAt(0).toUpperCase() + tile.linktype.slice(1)}`,
                    value: tile.linktype
                  })
                  setModalTile(true)
                  if (tile?.linktype === 'music') {
                    setCurrent({
                      ...tile?.payload,
                      backgroundImgUrl: tile?.backgroundimg
                    })
                  } else {
                    setCurrent(tile?.payload)
                  }
                  setUrlMedia(tile?.url || tile?.backgroundimg)
                  setTypeTile('tile')
                  setIdFotUpdate(tile.id)
                }}
                style={{ background: getBg(tile), height: getHeighForItem() }}
                className='tile-modal__item_tile'>
                <span
                  style={{ color: tile?.payload?.titleColor }}
                  className='tile-modal__main-title'>
                  {tile?.payload?.title}
                </span>
                <span className='tile-modal__head-link'>{tile?.linktype}</span>
                <div className='tile-modal__delete'
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsNotSave(true)
                    setCurrent(tile?.id)
                  }}
                >✖
                </div>
              </div>
            )
          })}
          <div onClick={(e) => {
            e.preventDefault()
            setTypeTile('tile')
            setTileLinktype('')
            setModalCreate(true)
          }}
            style={{ height: getHeighForItem() }}
            className='tile-modal__item-create'
          >+ Add
          </div>
        </div>
        <div className='tile-modal__help'>
          <div className='tile-modal__switcher-text'>Help</div>
          <div className='tile-modal__switcher'>
            <CustomSwitcher
              checked={tileSetting?.help}
              onChange={changeSwitch}
              isTileModal
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalTile