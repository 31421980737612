import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showSimpleModalAction } from '../../redux/actions';
import { onInputChange } from '../../helpers/formUtils';
import { ReactComponent as Upload } from '../../assets/icons/cloud-upload.svg';
import { ReactComponent as CleanUpload } from '../../assets/icons/trash.svg';
import './custom-upload-image.scss';

export const CustomUploadImage = ({ formData, setFormData, label }) => {
  const formField = label.toLowerCase();

  const value = formData?.[formField]?.value;

  const [, setFileLoaded] = useState(false);

  const dispatch = useDispatch();

  const file = useRef(null);

  const handleUpload = e => {
    let allowedExtension = ['png', 'svg', 'svg+xml', 'jpeg'];
    const image = e.target.files[0];
    if (!image) {
      return;
    }
    const getTypeImg = image?.type?.split('/').pop();
    if (!allowedExtension.includes(getTypeImg)) {
      //error
      dispatch(
        showSimpleModalAction({
          title: 'Error',
          text: 'Wrong file type, available JPG, JPEG, GIF, PNG',
        })
      );
      return;
    }
    setFileLoaded(true);
    onInputChange(formField, image, setFormData, formData);
  };

  return (
    <div className="custom-upload-image">
      <div className="custom-upload-image__label">{label}</div>
      <div className="custom-upload-image__upload-container">
        <button
          onClick={e => {
            e.preventDefault();
            file.current.click();
          }}
          className="custom-upload-image__upload custom-upload-image__upload-btn"
        >
          <Upload className="custom-upload-image__upload-icon" />
          <div className="custom-upload-image__upload-icon-text">upload</div>
        </button>
        <input
          ref={file}
          accept="image/*"
          type="file"
          onChange={handleUpload}
          style={{ display: 'none' }}
        />
        {value ? (
          <div className="custom-upload-image__upload-img-container">
            <div className="custom-upload-image__upload-img">
              <img
                src={
                  typeof value === 'string' ? value : URL.createObjectURL(value)
                }
                className="custom-upload-image__upload-image"
              />
              <button
                onClick={e => {
                  e.preventDefault();
                  setFileLoaded(false);
                  onInputChange(formField, null, setFormData, formData);
                  file.current.value = null;
                }}
                className="custom-upload-image__upload-clean-btn"
              >
                <CleanUpload className="custom-upload-image__upload-clean" />
              </button>
            </div>
          </div>
        ) : (
          <div className="custom-upload-image__upload">{label}</div>
        )}
      </div>
    </div>
  );
};
