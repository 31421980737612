import React, { useEffect, useReducer, useRef, useState } from 'react'
import { CustomModal } from '../../../../components/CustomModal/CustomModal'
import { CustomDropdown } from '../../../../components/CustomDropdown/CustomDropdown'
import Chrome from 'react-color/lib/components/chrome/Chrome'
import { useClickOutside } from '../../../../hooks/useClickOutside'
import rgbHex from 'rgb-hex'
import CustomTextEditor from '../../../../components/CustomTextEditor/CustomTextEditor'
import {
  fillFormAction,
  formsReducer,
  onFocusOut,
  onInputChange,
  toNull,
  validateForm
} from '../../../../helpers/formUtils'
import { ReactComponent as Plus } from '../../../../assets/icons/plus.svg'
import ReactPlayer from 'react-player'
import { CustomInput } from '../../../../components/CustomInput/CustomInput'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/src/styles.scss'
import { CustomSwitcher } from '../../../../components/CustomSwitcher/CustomSwitcher'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import { reduceFormData } from '../../../../helpers/reduceFormData'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoaderAction, showLoaderAction, showSimpleModalAction } from '../../../../redux/actions'
import { createHeroTile, createSimpleTile, updateSimpleTile, uploadMediaTile } from '../../../../crud/spaces/spaces'
import ModalTileVideoUrl from './ModalTileVideoUrl'

let optionHero = [
  { name: 'Text', value: 'text' },
  { name: 'Image', value: 'image' },
  { name: 'Music', value: 'music' },
  { name: 'Video', value: 'video' },
  { name: 'Iframe', value: 'iframe' },
]
let optionTile = [
  { name: 'Text', value: 'text' },
  { name: 'Image', value: 'image' },
  { name: 'Music', value: 'music' },
  { name: 'Video', value: 'video' },
  { name: 'Iframe', value: 'iframe' },
  { name: 'External Link - New Tab', value: 'newTab' },
  { name: 'Matterport Flyover', value: 'mpFlyover' },
  { name: 'Matterport Deeplink', value: 'mpDeeplink' },
]

const ModalTileType = ({
  close,
  typeTile,
  typeLink,
  current,
  setTileLinktype,
  spaceData,
  updateDate,
  currId,
  isCurrent,
  urlMedia,
  showcase
}) => {
  const colorPicker = useRef(null)
  const titleColorPicker = useRef(null)
  const file = useRef(null)
  const duplicateFile = useRef(null)
  const dispatch = useDispatch()
  useClickOutside(colorPicker, () => setOpenColorPicker(false))
  useClickOutside(titleColorPicker, () => setOpenTitleColorPicker(false))
  const [openColorPicker, setOpenColorPicker] = useState(false)
  const [openColorTitlePicker, setOpenTitleColorPicker] = useState(false)
  const [files, setFiles] = useState(null)
  const [filesDuplicate, setFilesDuplicate] = useState(null)
  const [data, setData] = useReducer(formsReducer, {})
  const [isEdit, setIsEdit] = useState(false)
  const [isDownload, setIsDownload] = useState(false)
  const [isNotSave, setIsNotSave] = useState(false)
  const [changeLink, setChangeLink] = useState({ isChange: false, event: null })
  const [openTooltip, setOpenTooltip] = useState(false)
  const [openUrlModal, setOpenUrlModal] = useState(false)
  const bgRef = useRef()
  const color = useSelector(state => state.profile.color);

  useClickOutside(bgRef, () => {
    if (openTooltip) {
      setOpenTooltip(false)
    }
  })

  const findSweep = async (sweepId) => {
    let { sweeps } = await showcase.Model.getData()
    let filter = sweeps.filter(sweep => sweep.uuid === sweepId)
    if (filter.length > 0) {
      return filter[0].position.x
    } else {
      return ''
    }
  }

  const handleOpenTooltip = () => {
    setOpenTooltip(true)
  }
  const handleChange = (color, event) => {
    onInputChange('background', '#' + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a), setData, data)
    setIsEdit(true)
  }
  const handleChangeTitle = (color, event) => {
    onInputChange('titleColor', '#' + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a), setData, data)
    setIsEdit(true)
  }
  const handleUpload = (e) => {
    const MAX_SIZE = 5_171_051
    let allowedExtension = ['png', 'svg', 'svg+xml', 'jpeg']
    const image = e.target.files[0]
    const getSizeImg = image?.size
    const getTypeImg = image?.type?.split('/').pop()
    if (getSizeImg > MAX_SIZE) {
      //error
      dispatch(showSimpleModalAction({ titel: 'Error', text: 'Max size allow 5mb' }))
      return
    }
    if (!allowedExtension.includes(getTypeImg)) {
      //error
      dispatch(showSimpleModalAction({ title: 'Error', text: 'Wrong file type, available JPG, JPEG, GIF, PNG' }))
      return
    }

    if (typeLink.value === 'music') {
      setFilesDuplicate(image)
    } else {
      setFiles(image)
    }
    let imgToBlog = URL.createObjectURL(image)
    setIsEdit(true)
    setIsDownload(true)
    onInputChange('image', imgToBlog, setData, data)
  }
  const handleUploadVideo = (e) => {
    let allowedExtension = ['mp4', 'm4v', 'webm', 'ogv', 'wmv', 'flv']
    const video = e.target.files[0]
    const getSizeVideo = video?.size
    const MAX_SIZE = 10_245_102
    const getTypeVideo = video?.type?.split('/').pop()
    if (getSizeVideo > MAX_SIZE) {
      //error
      dispatch(showSimpleModalAction({ title: 'Error', text: 'Max size allow 10mb' }))
      return
    }
    if (!allowedExtension.includes(getTypeVideo)) {
      //error
      dispatch(showSimpleModalAction({
        title: 'Error',
        text: 'Wrong file type, available mp4, m4v, webm, ogv, wmv, flv'
      }))
      return
    }
    setFiles(video)
    let videoToBlog = URL.createObjectURL(video)
    setIsEdit(true)
    setIsDownload(true)
    onInputChange('video', videoToBlog, setData, data)
    onInputChange('videoUrl', '', setData, data)
  }
  const handleUploadAudio = (e) => {
    let allowedExtension = ['mp3', 'wav', 'aac', 'flac', 'mpeg']
    const audio = e.target.files[0]
    const getSizeAudio = audio?.size
    const MAX_SIZE = 8_245_102
    const getTypeAudio = audio?.type?.split('/').pop()
    if (getSizeAudio > MAX_SIZE) {
      //error
      dispatch(showSimpleModalAction({ title: 'Error', text: 'Max size allow 8mb' }))
      return
    }
    if (!allowedExtension.includes(getTypeAudio)) {
      //error
      dispatch(showSimpleModalAction({ title: 'Error', text: 'Wrong file type, available mp3, wav, aac, flac, mpeg' }))
      return
    }
    setFiles(audio)
    let audioToBlog = URL.createObjectURL(audio)
    setIsEdit(true)
    setIsDownload(true)
    onInputChange('audio', audioToBlog, setData, data)
  }
  const renderType = () => {
    let type = typeLink?.value
    switch (type) {
      case 'text':
        return (
          <>
            {typeTile !== 'hero' ? (<>
              <div className='tile-modal__field'>
                <div className='tile-modal--subtitle'>Title</div>
                <CustomInput
                  name="text"
                  maxLength={30}
                  formData={data?.tileTitle}
                  onChange={e => {
                    onInputChange('tileTitle', e.target.value, setData, data)
                    setIsEdit(true)
                  }}
                  onBlur={e => {
                    onFocusOut('tileTitle', e.target.value, setData, data)
                    setIsEdit(true)
                  }}
                  placeholder="Title"
                  variantError="topright"
                />
              </div>
              <div className='tile-modal__field'>
                <div className='tile-modal--subtitle tile-modal--mb'>Title Color</div>
                <div className='tile-modal__bg'>
                  <div className='tile-modal__hex'>
                    {data?.titleColor?.value || '#1daeef'}
                  </div>
                  <div onClick={(e) => {
                    e.stopPropagation()
                    setOpenTitleColorPicker(true)
                  }}
                    className='custom-icon__color'
                    style={{ backgroundColor: `${data?.titleColor?.value || '#1daeef'}` }}
                  >
                  </div>
                  {openColorTitlePicker &&
                    <div
                      ref={titleColorPicker}
                      className='custom-icon__picker tile-modal__picker'>
                      <Chrome
                        disableAlpha={false}
                        color={data?.titleColor?.value}
                        onChange={handleChangeTitle}
                      />
                    </div>}
                </div>
              </div>
            </>) : null}
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle'>Text</div>
              <CustomTextEditor
                poiEdit={data}
                setPoiEdit={setData}
                setEdited={setIsEdit}
                maxSize={500}
                withValid={true}
                isAwait={true}
                formName='descriptions'
                isTile={true}
              />
            </div>
            {typeTile === 'hero' ? (<>
              <div className='tile-modal__field'>
                <div className='tile-modal--subtitle tile-modal--mb'>Text Color</div>
                <div className='tile-modal__bg'>
                  <div className='tile-modal__hex'>
                    {data?.titleColor?.value || '#1daeef'}
                  </div>
                  <div onClick={(e) => {
                    e.stopPropagation()
                    setOpenTitleColorPicker(true)
                  }}
                    className='custom-icon__color'
                    style={{ backgroundColor: `${data?.titleColor?.value || '#1daeef'}` }}
                  >
                  </div>
                  {openColorTitlePicker &&
                    <div
                      ref={titleColorPicker}
                      className='custom-icon__picker tile-modal__picker'>
                      <Chrome
                        disableAlpha={false}
                        color={data?.titleColor?.value}
                        onChange={handleChangeTitle}
                      />
                    </div>}
                </div>
              </div>
            </>) : null}
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle tile-modal--mb'>Background Color</div>
              <div className='tile-modal__bg'>
                <div className='tile-modal__hex'>
                  {data?.background?.value || '#1daeef'}
                </div>
                <div onClick={(e) => {
                  e.stopPropagation()
                  setOpenColorPicker(true)
                }}
                  className='custom-icon__color'
                  style={{ backgroundColor: `${data?.background?.value || '#1daeef'}` }}
                >
                </div>
                {openColorPicker &&
                  <div
                    ref={colorPicker}
                    className='custom-icon__picker tile-modal__picker'>
                    <Chrome
                      disableAlpha={false}
                      color={data?.background?.value}
                      onChange={handleChange}
                    />
                  </div>}
              </div>
            </div>
          </>
        )
      case 'image':
        return (
          <>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle'>Title</div>
              <CustomInput
                name="text"
                formData={data?.tileTitle}
                maxLength={30}
                onChange={e => {
                  onInputChange('tileTitle', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                onBlur={e => {
                  onFocusOut('tileTitle', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                placeholder="Title"
                variantError="topright"
              />
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle tile-modal--mb'>Title Color</div>
              <div className='tile-modal__bg'>
                <div className='tile-modal__hex'>
                  {data?.titleColor?.value || '#1daeef'}
                </div>
                <div onClick={(e) => {
                  e.stopPropagation()
                  setOpenTitleColorPicker(true)
                }}
                  className='custom-icon__color'
                  style={{ backgroundColor: `${data?.titleColor?.value || '#1daeef'}` }}
                >
                </div>
                {openColorTitlePicker &&
                  <div
                    ref={titleColorPicker}
                    className='custom-icon__picker tile-modal__picker'>
                    <Chrome
                      disableAlpha={false}
                      color={data?.titleColor?.value}
                      onChange={handleChangeTitle}
                    />
                  </div>}
              </div>
            </div>
            <div className='tile-modal__field tile-modal__field--size'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  file.current.click()
                }}
                className='tile-modal__btn'
              >
                <Plus fill={color} />
              </button>
              <input
                ref={file}
                className='file-upload'
                accept="image/*"
                type="file"
                onChange={handleUpload}
                style={{ display: 'none' }} />
              {data?.image?.value && <div className='tile-modal__upload-img'>
                <img src={data?.image?.value} className='tile-modal__upload-image' />
              </div>}
            </div>
          </>
        )
      case 'video':
        return (
          <>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle'>Title</div>
              <CustomInput
                name="text"
                formData={data?.tileTitle}
                maxLength={30}
                onChange={e => {
                  onInputChange('tileTitle', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                onBlur={e => {
                  onFocusOut('tileTitle', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                placeholder="Title"
                variantError="topright"
              />
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle tile-modal--mb'>Title Color</div>
              <div className='tile-modal__bg'>
                <div className='tile-modal__hex'>
                  {data?.titleColor?.value || '#1daeef'}
                </div>
                <div onClick={(e) => {
                  e.stopPropagation()
                  setOpenTitleColorPicker(true)
                }}
                  className='custom-icon__color'
                  style={{ backgroundColor: `${data?.titleColor?.value || '#1daeef'}` }}
                >
                </div>
                {openColorTitlePicker &&
                  <div
                    ref={titleColorPicker}
                    className='custom-icon__picker tile-modal__picker'>
                    <Chrome
                      disableAlpha={false}
                      color={data?.titleColor?.value}
                      onChange={handleChangeTitle}
                    />
                  </div>}
              </div>
            </div>
            <div className='tile-modal__field tile-modal__field--size'>
              <button
                onClick={handleOpenTooltip}
                className='tile-modal__btn'
              >
                <Plus fill={color} />
              </button>
              {openTooltip && <div className='tile-modal__popper' ref={bgRef}>
                <span onClick={() => {
                  file.current.click()
                }}>Add Video File</span>
                <span onClick={() => {
                  setOpenUrlModal(true)
                }}>Add URL</span>
              </div>}
              <input
                ref={file}
                className='file-upload'
                accept="video/mp4,video/x-m4v,video/*"
                type="file"
                onChange={handleUploadVideo}
                style={{ display: 'none' }} />
            </div>
            <div className='tile-modal__field'>
              {(data?.video?.value || data?.videoUrl?.value) && <div className='tile-modal__upload-img'>
                <ReactPlayer
                  width='100%'
                  height='100%'
                  url={data?.video?.value || data?.videoUrl?.value}
                  controls={true}
                />
              </div>}
            </div>
          </>
        )
      case 'iframe':
        return (
          <>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle'>Title</div>
              <CustomInput
                name="text"
                formData={data?.tileTitle}
                maxLength={30}
                onChange={e => {
                  onInputChange('tileTitle', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                onBlur={e => {
                  onFocusOut('tileTitle', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                placeholder="Title"
                variantError="topright"
              />
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle tile-modal--mb'>Title Color</div>
              <div className='tile-modal__bg'>
                <div className='tile-modal__hex'>
                  {data?.titleColor?.value || '#1daeef'}
                </div>
                <div onClick={(e) => {
                  e.stopPropagation()
                  setOpenTitleColorPicker(true)
                }}
                  className='custom-icon__color'
                  style={{ backgroundColor: `${data?.titleColor?.value || '#1daeef'}` }}
                >
                </div>
                {openColorTitlePicker &&
                  <div
                    ref={titleColorPicker}
                    className='custom-icon__picker tile-modal__picker'>
                    <Chrome
                      disableAlpha={false}
                      color={data?.titleColor?.value}
                      onChange={handleChangeTitle}
                    />
                  </div>}
              </div>
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle'>URL</div>
              <CustomInput
                name="url"
                formData={data?.url}
                onChange={e => {
                  onInputChange('url', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                onBlur={e => {
                  onFocusOut('url', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                variantError="topright"
              />
            </div>
            {data?.url?.value && !data?.url?.hasError &&
              <iframe
                title="Iframe akrotonx"
                className='tile-modal-type__iframe'
                referrerPolicy='no-referrer'
                src={data?.url?.value}
              />}
            <div className='tile-modal__field tile-modal__field--flex'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  file.current.click()
                }}
                className='tile-modal__btn'
              >
                <Plus fill={color} />
              </button>
              <span className='tile-modal__bg-info'>Add Background Image </span>
              <input
                ref={file}
                className='file-upload'
                accept="image/*"
                type="file"
                onChange={handleUpload}
                style={{ display: 'none' }} />
              {data?.image?.value && <div className='tile-modal__upload-img tile-modal__upload-img--size'>
                <img src={data?.image?.value} className='tile-modal__upload-image tile-modal__upload-image--size' />
              </div>}
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle tile-modal--mb'>Background Color</div>
              <div className='tile-modal__bg'>
                <div className='tile-modal__hex'>
                  #76CEDC
                </div>
                <div onClick={(e) => {
                  e.stopPropagation()
                  setOpenColorPicker(true)
                }}
                  className='custom-icon__color'
                  style={{ backgroundColor: `${data?.background?.value || '#1daeef'}` }}
                >
                </div>
                {openColorPicker &&
                  <div
                    ref={colorPicker}
                    className='custom-icon__picker tile-modal__picker'>
                    <Chrome
                      disableAlpha={false}
                      color={data?.background?.value}
                      onChange={handleChange}
                    />
                  </div>}
              </div>
            </div>
          </>
        )
      case 'music':
        return (
          <>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle'>Title</div>
              <CustomInput
                name="text"
                formData={data?.tileTitle}
                maxLength={30}
                onChange={e => {
                  onInputChange('tileTitle', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                onBlur={e => {
                  onFocusOut('tileTitle', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                placeholder="Title"
                variantError="topright"
              />
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle tile-modal--mb'>Title Color</div>
              <div className='tile-modal__bg'>
                <div className='tile-modal__hex'>
                  {data?.titleColor?.value || '#1daeef'}
                </div>
                <div onClick={(e) => {
                  e.stopPropagation()
                  setOpenTitleColorPicker(true)
                }}
                  className='custom-icon__color'
                  style={{ backgroundColor: `${data?.titleColor?.value || '#1daeef'}` }}
                >
                </div>
                {openColorTitlePicker &&
                  <div
                    ref={titleColorPicker}
                    className='custom-icon__picker tile-modal__picker'>
                    <Chrome
                      disableAlpha={false}
                      color={data?.titleColor?.value}
                      onChange={handleChangeTitle}
                    />
                  </div>}
              </div>
            </div>
            <div className='tile-modal__field tile-modal__field--size'>
              <button
                variant="contained"
                onClick={handleOpenTooltip}
                className='tile-modal__btn'
              >
                <Plus fill={color} />
              </button>
              {openTooltip && <div className='tile-modal__popper' ref={bgRef}>
                <span onClick={() => {
                  file.current.click()
                }}>Add Music File</span>
                <span onClick={() => {
                  duplicateFile.current.click()
                }}>Add Background Image</span>
              </div>}
              <input
                ref={file}
                className='file-upload'
                accept=".mp3,audio/*"
                type="file"
                onChange={handleUploadAudio}
                style={{ display: 'none' }} />
              <input
                ref={duplicateFile}
                className='file-upload'
                accept="image/*"
                type="file"
                onChange={handleUpload}
                style={{ display: 'none' }} />
            </div>
            {data?.audio?.value && <div className='tile-modal__field'>
              <div className='tile-modal__upload-audio'>
                <AudioPlayer
                  autoPlay={data?.autoplay?.value}
                  src={data?.audio?.value}
                  onPlay={e => console.log('onPlay')}
                  onPlayError={(err) => {
                    debugger
                  }}
                  // other props here
                  header={`Now playing:`}
                  showSkipControls={false}
                  showJumpControls={false}
                  className='tile-modal__audio'
                  customControlsSection={['MAIN_CONTROLS', 'VOLUME_CONTROLS']}
                />
              </div>
            </div>}
            {data?.image?.value && <div className='tile-modal__field'>
              <div className='tile-modal__upload-img tile-modal__upload-img--start'>
                <img src={data?.image?.value} className='tile-modal__upload-image tile-modal__upload-image--size' />
              </div>
            </div>}
            <div className='tile-modal__field'>
              <div className='tile-modal__checkbox'>
                <span className='tile-modal--subtitle'>Autoplay</span>
                <CustomSwitcher
                  checked={data?.autoplay?.value}
                  onChange={(e) => {
                    setIsEdit(true)
                    let value = e.target.checked
                    onInputChange('autoplay', value, setData, data)
                  }}
                />
              </div>
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle tile-modal--mb'>Background Color</div>
              <div className='tile-modal__bg'>
                <div className='tile-modal__hex'>
                  #76CEDC
                </div>
                <div onClick={(e) => {
                  e.stopPropagation()
                  setOpenColorPicker(true)
                }}
                  className='custom-icon__color'
                  style={{ backgroundColor: `${data?.background?.value || '#1daeef'}` }}
                >
                </div>
                {openColorPicker &&
                  <div
                    ref={colorPicker}
                    className='custom-icon__picker tile-modal__picker'>
                    <Chrome
                      disableAlpha={false}
                      color={data?.background?.value}
                      onChange={handleChange}
                    />
                  </div>}
              </div>
            </div>
          </>
        )
      case 'newTab':
        return (
          <>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle'>Title</div>
              <CustomInput
                name="text"
                formData={data?.tileTitle}
                maxLength={30}
                onChange={e => {
                  onInputChange('tileTitle', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                onBlur={e => {
                  onFocusOut('tileTitle', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                placeholder="Title"
                variantError="topright"
              />
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle tile-modal--mb'>Title Color</div>
              <div className='tile-modal__bg'>
                <div className='tile-modal__hex'>
                  {data?.titleColor?.value || '#1daeef'}
                </div>
                <div onClick={(e) => {
                  e.stopPropagation()
                  setOpenTitleColorPicker(true)
                }}
                  className='custom-icon__color'
                  style={{ backgroundColor: `${data?.titleColor?.value || '#1daeef'}` }}
                >
                </div>
                {openColorTitlePicker &&
                  <div
                    ref={titleColorPicker}
                    className='custom-icon__picker tile-modal__picker'>
                    <Chrome
                      disableAlpha={false}
                      color={data?.titleColor?.value}
                      onChange={handleChangeTitle}
                    />
                  </div>}
              </div>
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle'>URL</div>
              <CustomInput
                name="url"
                formData={data?.url}
                onChange={e => {
                  onInputChange('url', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                onBlur={e => {
                  onFocusOut('url', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                variantError="topright"
              />
            </div>
            <div className='tile-modal__field tile-modal__field--flex'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  file.current.click()
                }}
                className='tile-modal__btn'
              >
                <Plus fill={color} />
              </button>
              <span className='tile-modal__bg-info'>Add Background Image </span>
              <input
                ref={file}
                className='file-upload'
                accept="image/*"
                type="file"
                onChange={handleUpload}
                style={{ display: 'none' }} />
              {data?.image?.value && <div className='tile-modal__upload-img tile-modal__upload-img--size'>
                <img src={data?.image?.value} className='tile-modal__upload-image tile-modal__upload-image--size' />
              </div>}
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle tile-modal--mb'>Background Color</div>
              <div className='tile-modal__bg'>
                <div className='tile-modal__hex'>
                  #76CEDC
                </div>
                <div onClick={(e) => {
                  e.stopPropagation()
                  setOpenColorPicker(true)
                }}
                  className='custom-icon__color'
                  style={{ backgroundColor: `${data?.background?.value || '#1daeef'}` }}
                >
                </div>
                {openColorPicker &&
                  <div
                    ref={colorPicker}
                    className='custom-icon__picker tile-modal__picker'>
                    <Chrome
                      disableAlpha={false}
                      color={data?.background?.value}
                      onChange={handleChange}
                    />
                  </div>}
              </div>
            </div>
          </>
        )
      case 'mpFlyover':
        return (
          <>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle'>Title</div>
              <CustomInput
                name="text"
                formData={data?.tileTitle}
                maxLength={30}
                onChange={e => {
                  onInputChange('tileTitle', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                onBlur={e => {
                  onFocusOut('tileTitle', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                placeholder="Title"
                variantError="topright"
              />
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle tile-modal--mb'>Title Color</div>
              <div className='tile-modal__bg'>
                <div className='tile-modal__hex'>
                  {data?.titleColor?.value || '#1daeef'}
                </div>
                <div onClick={(e) => {
                  e.stopPropagation()
                  setOpenTitleColorPicker(true)
                }}
                  className='custom-icon__color'
                  style={{ backgroundColor: `${data?.titleColor?.value || '#1daeef'}` }}
                >
                </div>
                {openColorTitlePicker &&
                  <div
                    ref={titleColorPicker}
                    className='custom-icon__picker tile-modal__picker'>
                    <Chrome
                      disableAlpha={false}
                      color={data?.titleColor?.value}
                      onChange={handleChangeTitle}
                    />
                  </div>}
              </div>
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle'>URL</div>
              <CustomInput
                name="url"
                formData={data?.urls}
                disabled={true}
                onChange={e => {
                  onInputChange('urls', e.target.value, setData, data)
                }}
                onBlur={e => {
                  onFocusOut('urls', e.target.value, setData, data)
                }}
                variantError="topright"
              />
            </div>
            <div className='tile-modal__field tile-modal__field--flex'>
              <button
                onClick={async (e) => {
                  e.preventDefault()
                  let currentLocation = await showcase.Camera.getPose()
                  let sweep = await findSweep(currentLocation.sweep)
                  let createSaveLink = '&' + JSON.stringify(currentLocation.position) +
                    '&' + JSON.stringify(currentLocation.rotation) + '&' + currentLocation.mode + '&' + sweep
                  onInputChange('urls', createSaveLink, setData, data)
                }}
                className='tile-modal__btn-link'
              >
                Generate a link
              </button>
            </div>
            <div className='tile-modal__field tile-modal__field--flex'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  file.current.click()
                }}
                className='tile-modal__btn'
              >
                <Plus fill={color} />
              </button>
              <span className='tile-modal__bg-info'>Add Background Image </span>
              <input
                ref={file}
                className='file-upload'
                accept="image/*"
                type="file"
                onChange={handleUpload}
                style={{ display: 'none' }} />
              {data?.image?.value && <div className='tile-modal__upload-img tile-modal__upload-img--size'>
                <img src={data?.image?.value} className='tile-modal__upload-image tile-modal__upload-image--size' />
              </div>}
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle tile-modal--mb'>Background Color</div>
              <div className='tile-modal__bg'>
                <div className='tile-modal__hex'>
                  {data?.background?.value || '#1daeef'}
                </div>
                <div onClick={(e) => {
                  e.stopPropagation()
                  setOpenColorPicker(true)
                }}
                  className='custom-icon__color'
                  style={{ backgroundColor: `${data?.background?.value || '#1daeef'}` }}
                >
                </div>
                {openColorPicker &&
                  <div
                    ref={colorPicker}
                    className='custom-icon__picker tile-modal__picker'>
                    <Chrome
                      disableAlpha={false}
                      color={data?.background?.value}
                      onChange={handleChange}
                    />
                  </div>}
              </div>
            </div>
          </>
        )
      case 'mpDeeplink':
        return (
          <>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle'>Title</div>
              <CustomInput
                name="text"
                formData={data?.tileTitle}
                maxLength={30}
                onChange={e => {
                  onInputChange('tileTitle', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                onBlur={e => {
                  onFocusOut('tileTitle', e.target.value, setData, data)
                  setIsEdit(true)
                }}
                placeholder="Title"
                variantError="topright"
              />
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle tile-modal--mb'>Title Color</div>
              <div className='tile-modal__bg'>
                <div className='tile-modal__hex'>
                  {data?.titleColor?.value || '#1daeef'}
                </div>
                <div onClick={(e) => {
                  e.stopPropagation()
                  setOpenTitleColorPicker(true)
                }}
                  className='custom-icon__color'
                  style={{ backgroundColor: `${data?.titleColor?.value || '#1daeef'}` }}
                >
                </div>
                {openColorTitlePicker &&
                  <div
                    ref={titleColorPicker}
                    className='custom-icon__picker tile-modal__picker'>
                    <Chrome
                      disableAlpha={false}
                      color={data?.titleColor?.value}
                      onChange={handleChangeTitle}
                    />
                  </div>}
              </div>
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle'>URL</div>
              <CustomInput
                name="url"
                formData={data?.urls}
                disabled={true}
                onChange={e => {
                  onInputChange('urls', e.target.value, setData, data)
                }}
                onBlur={e => {
                  onFocusOut('urls', e.target.value, setData, data)
                }}
                variantError="topright"
              />
            </div>
            <div className='tile-modal__field tile-modal__field--flex'>
              <button
                onClick={async (e) => {
                  e.preventDefault()
                  let currentLocation = await showcase.Camera.getPose()
                  let sweep = await findSweep(currentLocation.sweep)
                  let createSaveLink = '&' + JSON.stringify(currentLocation.position) +
                    '&' + JSON.stringify(currentLocation.rotation) + '&' + currentLocation.mode + '&' + sweep
                  onInputChange('urls', createSaveLink, setData, data)
                }}
                className='tile-modal__btn-link'
              >
                Generate a link
              </button>
            </div>
            <div className='tile-modal__field tile-modal__field--flex'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  file.current.click()
                }}
                className='tile-modal__btn'
              >
                <Plus fill={color} />
              </button>
              <span className='tile-modal__bg-info'>Add Background Image </span>
              <input
                ref={file}
                className='file-upload'
                accept="image/*"
                type="file"
                onChange={handleUpload}
                style={{ display: 'none' }} />
              {data?.image?.value && <div className='tile-modal__upload-img tile-modal__upload-img--size'>
                <img src={data?.image?.value} className='tile-modal__upload-image tile-modal__upload-image--size' />
              </div>}
            </div>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle tile-modal--mb'>Background Color</div>
              <div className='tile-modal__bg'>
                <div className='tile-modal__hex'>
                  {data?.background?.value || '#1daeef'}
                </div>
                <div onClick={(e) => {
                  e.stopPropagation()
                  setOpenColorPicker(true)
                }}
                  className='custom-icon__color'
                  style={{ backgroundColor: `${data?.background?.value || '#1daeef'}` }}
                >
                </div>
                {openColorPicker &&
                  <div
                    ref={colorPicker}
                    className='custom-icon__picker tile-modal__picker'>
                    <Chrome
                      disableAlpha={false}
                      color={data?.background?.value}
                      onChange={handleChange}
                    />
                  </div>}
              </div>
            </div>
          </>
        )
      default:
        return <div></div>
    }
  }
  const changeSlider = (e, value) => {
    onInputChange('height', value, setData, data)
    setIsEdit(true)
  }
  const isCanClose = () => {
    if (isEdit) {
      setIsNotSave(true)
    } else {
      close()
    }
  }

  const changeType = (e) => {
    setChangeLink({ isChange: true, event: e })
    setIsNotSave(true)
    setOpenTooltip(false)
  }
  const typePatch = (typeLink, type, current) => {
    let isHero = type === 'hero'
    let isCurrent = !!current
    let reduced = reduceFormData(data)
    const formData = new FormData()
    switch (typeLink) {
      case 'text':
        formData.append('tileLinktype', 'text')
        if (!isHero) {
          formData.append('payload[text]', reduced.descriptions)
          formData.append('payload[title]', reduced.tileTitle || '')
          formData.append('payload[titleColor]', reduced.titleColor)
          formData.append('payload[backgroundColor]', reduced.background)
        } else {
          formData.append('payload[backgroundColor]', reduced.background)
          formData.append('payload[text]', reduced.descriptions)
          formData.append('payload[titleColor]', reduced.titleColor)
        }
        return {
          main: formData,
          isHero: isHero,
        }
      case 'image':
        formData.append('tileLinktype', 'image')
        if (files) {
          formData.append('image', files)
        }
        formData.append('payload[title]', reduced.tileTitle || '')
        formData.append('payload[titleColor]', reduced.titleColor)
        return {
          main: formData,
          isHero: isHero,
        }
      case 'iframe':
        formData.append('tileLinktype', 'iframe')
        formData.append('payload[backgroundColor]', reduced.background)
        if (files) {
          formData.append('backgroundImg', files)
        }
        formData.append('payload[url]', reduced.url)
        formData.append('payload[title]', reduced.tileTitle || '')
        formData.append('payload[titleColor]', reduced.titleColor)
        return {
          main: formData,
          isHero: isHero,
        }
      case 'music':
        formData.append('tileLinktype', 'music')
        formData.append('payload[backgroundColor]', reduced.background)
        if (files) {
          formData.append('music', files)
        }
        if (filesDuplicate) {
          formData.append('backgroundImg', filesDuplicate)
        }
        formData.append('payload[title]', reduced.tileTitle || '')
        formData.append('payload[autoplay]', data?.autoplay?.value)
        formData.append('payload[titleColor]', reduced.titleColor)
        return {
          main: formData,
          isHero: isHero,
        }
      case 'video':
        formData.append('tileLinktype', 'video')
        if (files) {
          formData.append('video', files)
        }
        if (reduced.videoUrl) {
          formData.append('videoUrl', reduced.videoUrl)
        }
        formData.append('payload[title]', reduced.tileTitle || '')
        formData.append('payload[titleColor]', reduced.titleColor)
        return {
          main: formData,
          isHero: isHero,
        }
      case 'newTab':
        if (files) {
          formData.append('backgroundImg', files)
        }
        formData.append('payload[url]', reduced.url)
        formData.append('payload[title]', reduced.tileTitle || '')
        formData.append('payload[titleColor]', reduced.titleColor)
        formData.append('payload[backgroundColor]', reduced.background)
        formData.append('tileLinktype', 'newTab')
        return {
          main: formData,
          isHero: isHero,
        }
      case 'mpFlyover':
        if (files) {
          formData.append('backgroundImg', files)
        }
        formData.append('payload[url]', reduced.urls)
        formData.append('payload[title]', reduced.tileTitle || '')
        formData.append('payload[titleColor]', reduced.titleColor)
        formData.append('payload[backgroundColor]', reduced.background)
        formData.append('tileLinktype', 'mpFlyover')
        return {
          main: formData,
          isHero: isHero,
        }
      case 'mpDeeplink':
        if (files) {
          formData.append('backgroundImg', files)
        }
        formData.append('payload[url]', reduced.urls)
        formData.append('payload[title]', reduced.tileTitle || '')
        formData.append('payload[titleColor]', reduced.titleColor)
        formData.append('payload[backgroundColor]', reduced.background)
        formData.append('tileLinktype', 'mpDeeplink')
        return {
          main: formData,
          isHero: isHero,
        }
      default:
        return
    }
  }

  const checkIsUpdate = () => {
    let result = false
    if (current || isCurrent) {
      return result
    } else {
      result = true
      return result
    }
  }
  const createTile = (data) => {
    if (!isEdit) {
      dispatch(showSimpleModalAction({
        title: 'Error',
        text: 'The form must not be empty or you need to change the values'
      }))
      return
    }
    dispatch(showLoaderAction())
    let isCreate = checkIsUpdate()
    isCreate ? createSimpleTile(spaceData.id, data)
      .then(res => {
        // dispatch(showSimpleModalAction({ title: 'Success', text: 'You have successfully create/edit hero tile' }))
        setIsEdit(false)
        updateDate()
        close()
      })
      .catch(err => {
        const errors = err?.response?.data
        let { error, message, statusCode } = errors
        let errorMessage
        if (Array.isArray(message)) {
          let getMessageArray = message[0].constraints
          for (const element in getMessageArray) {
            errorMessage = getMessageArray[element]
          }
        } else {
          errorMessage = message
        }
        dispatch(showSimpleModalAction({ title: 'Error', text: errorMessage }))
      })
      .finally(() => {
        dispatch(hideLoaderAction())
      }) :
      updateSimpleTile(spaceData.id, currId, data)
        .then(res => {
          //dispatch(showSimpleModalAction({ title: 'Success', text: 'You have successfully create/edit hero tile' }))
          setIsEdit(false)
          updateDate()
          close()
        })
        .catch(err => {
          const errors = err?.response?.data
          const { error, message, statusCode } = errors
          let errorMessage
          if (Array.isArray(message)) {
            let getMessageArray = message[0].constraints
            for (const element in getMessageArray) {
              errorMessage = getMessageArray[element]
            }
          } else {
            errorMessage = message
          }
          dispatch(showSimpleModalAction({ title: 'Error', text: errorMessage }))
        })
        .finally(() => {
          dispatch(hideLoaderAction())
        })
  }
  const createHerosTile = (data) => {
    if (!isEdit) {
      dispatch(showSimpleModalAction({
        title: 'Error',
        text: 'The form must not be empty or you need to change the values'
      }))
      return
    }
    dispatch(showLoaderAction())
    createHeroTile(spaceData.id, data)
      .then(res => {
        //dispatch(showSimpleModalAction({ title: 'Success', text: 'You have successfully create/edit hero tile' }))
        setIsEdit(false)
        updateDate()
        close()
      })
      .catch(err => {
        const errors = err?.response?.data
        const { error, message, statusCode } = errors
        let mess = message
        if (message?.length) {
          mess = message[0]?.constraints?.maxLength
        }
        dispatch(showSimpleModalAction({ title: 'Error', text: mess }))
      })
      .finally(() => {
        dispatch(hideLoaderAction())
      })
  }

  const sumbitType = async () => {
    if (validateForm(data, setData)) {
      let createNew = typePatch(typeLink.value, typeTile)
      createNew.isHero ? createHerosTile(createNew.main) : createTile(createNew.main)
    } else {
      return
    }
  }
  useEffect(() => {
    if (typeLink?.value && typeTile) {
      switch (typeLink?.value) {
        case 'text':
          if (typeTile === 'hero') {
            setData(fillFormAction({
              descriptions: { value: current?.payload?.text || '', touched: false, hasError: true, error: '' },
              background: {
                value: current?.payload?.backgroundColor || color,
                touched: false,
                hasError: true,
                error: ''
              },
              titleColor: { value: current?.payload?.titleColor || '#ffffff', touched: false, hasError: true, error: '' },
            }))
          } else {
            setData(fillFormAction({
              tileTitle: { value: current?.title || '', touched: false, hasError: true, error: '' },
              titleColor: { value: current?.titleColor || '#ffffff', touched: false, hasError: true, error: '' },
              descriptions: { value: current?.text || '', touched: false, hasError: true, error: '' },
              background: { value: current?.backgroundColor || color, touched: false, hasError: true, error: '' },
            }))
          }
          break
        case 'image':
          if (typeTile === 'hero') {
            setData(fillFormAction({
              image: { value: urlMedia || '', touched: false, hasError: true, error: '' },
              tileTitle: { value: current?.payload?.title || '', touched: false, hasError: true, error: '' },
              titleColor: {
                value: current?.payload?.titleColor || '#ffffff',
                touched: false,
                hasError: true,
                error: ''
              },
            }))
          } else {
            setData(fillFormAction({
              image: { value: urlMedia || '', touched: false, hasError: true, error: '' },
              tileTitle: { value: current?.title || '', touched: false, hasError: true, error: '' },
              titleColor: { value: current?.titleColor || '#ffffff', touched: false, hasError: true, error: '' },
            }))
          }
          break
        case 'video':
          if (typeTile === 'hero') {
            setData(fillFormAction({
              videoUrl: { value: urlMedia || '', touched: false, hasError: true, error: '' },
              video: { value: urlMedia || '', touched: false, hasError: true, error: '' },
              tileTitle: { value: current?.payload?.title || '', touched: false, hasError: true, error: '' },
              titleColor: {
                value: current?.payload?.titleColor || '#ffffff',
                touched: false,
                hasError: true,
                error: ''
              },
            }))
          } else {
            setData(fillFormAction({
              videoUrl: { value: urlMedia || '', touched: false, hasError: true, error: '' },
              video: { value: urlMedia || '', touched: false, hasError: true, error: '' },
              tileTitle: { value: current?.title || '', touched: false, hasError: true, error: '' },
              titleColor: { value: current?.titleColor || '#ffffff', touched: false, hasError: true, error: '' },
            }))
          }
          break
        case 'iframe':
          if (typeTile === 'hero') {
            setData(fillFormAction({
              image: { value: current?.backgroundImgUrl || '', touched: false, hasError: true, error: '' },
              url: { value: current?.payload?.url, touched: false, hasError: true, error: '' },
              tileTitle: { value: current?.payload?.title || '', touched: false, hasError: true, error: '' },
              titleColor: {
                value: current?.payload?.titleColor || '#ffffff',
                touched: false,
                hasError: true,
                error: ''
              },
              background: { value: current?.backgroundColor || color, touched: false, hasError: true, error: '' },
            }))
          } else {
            setData(fillFormAction({
              url: { value: current?.url, touched: false, hasError: true, error: '' },
              image: { value: urlMedia || '', touched: false, hasError: true, error: '' },
              tileTitle: { value: current?.title || '', touched: false, hasError: true, error: '' },
              titleColor: { value: current?.titleColor || '#ffffff', touched: false, hasError: true, error: '' },
              background: { value: current?.backgroundColor || color, touched: false, hasError: true, error: '' },
            }))
          }
          break
        case 'music':
          if (typeTile === 'hero') {
            setData(fillFormAction({
              audio: { value: urlMedia || '', touched: false, hasError: true, error: '' },
              autoplay: {
                value: (current?.payload?.autoplay === 'true') || false,
                touched: false,
                hasError: true,
                error: ''
              },
              image: { value: current?.backgroundImgUrl || '', touched: false, hasError: true, error: '' },
              tileTitle: { value: current?.payload?.title || '', touched: false, hasError: true, error: '' },
              titleColor: {
                value: current?.payload?.titleColor || '#ffffff',
                touched: false,
                hasError: true,
                error: ''
              },
              background: { value: current?.backgroundColor || color, touched: false, hasError: true, error: '' },
            }))
          } else {
            setData(fillFormAction({
              audio: { value: urlMedia || '', touched: false, hasError: true, error: '' },
              autoplay: { value: (current?.autoplay === 'true') || false, touched: false, hasError: true, error: '' },
              image: { value: current?.backgroundImgUrl || '', touched: false, hasError: true, error: '' },
              tileTitle: { value: current?.title || '', touched: false, hasError: true, error: '' },
              titleColor: { value: current?.titleColor || '#ffffff', touched: false, hasError: true, error: '' },
              background: { value: current?.backgroundColor || color, touched: false, hasError: true, error: '' },
            }))
          }
          break
        case 'newTab':
          setData(fillFormAction({
            url: { value: current?.url || '', touched: false, hasError: true, error: '' },
            tileTitle: { value: current?.title || '', touched: false, hasError: true, error: '' },
            titleColor: { value: current?.titleColor || '#ffffff', touched: false, hasError: true, error: '' },
            background: { value: current?.backgroundColor || color, touched: false, hasError: true, error: '' },
            image: { value: urlMedia || '', touched: false, hasError: true, error: '' },
          }))
          break
        case 'mpFlyover':
          setData(fillFormAction({
            urls: { value: current?.url || '', touched: false, hasError: true, error: '' },
            tileTitle: { value: current?.title || '', touched: false, hasError: true, error: '' },
            titleColor: { value: current?.titleColor || '#ffffff', touched: false, hasError: true, error: '' },
            background: { value: current?.backgroundColor || color, touched: false, hasError: true, error: '' },
            image: { value: urlMedia || '', touched: false, hasError: true, error: '' },
          }))
          break
        case 'mpDeeplink':
          setData(fillFormAction({
            urls: { value: current?.url || '', touched: false, hasError: true, error: '' },
            tileTitle: { value: current?.title || '', touched: false, hasError: true, error: '' },
            titleColor: { value: current?.titleColor || '#ffffff', touched: false, hasError: true, error: '' },
            background: { value: current?.backgroundColor || color, touched: false, hasError: true, error: '' },
            image: { value: urlMedia || '', touched: false, hasError: true, error: '' },
          }))
          break
        default:
          return <div></div>
      }
    } else {

    }
  }, [current, typeLink, typeTile, color])
  return (
    <>
      {openUrlModal &&
        <ModalTileVideoUrl
          data={data?.videoUrl?.value}
          submit={(filed) => {
            onInputChange('video', '', setData, data)
            setFiles(null)
            onInputChange('videoUrl', filed, setData, data)
            setIsEdit(true)
            setOpenUrlModal(false)
          }}
          close={() => setOpenUrlModal(false)}
        />
      }
      <div className='tile-modal-type'>
        {isNotSave && <ConfirmModal
          title="Changes not saved"
          cancelBtn="No"
          submitBtn="Yes"
          submit={() => {
            setIsNotSave(false)
            if (changeLink.isChange) {
              setTileLinktype(changeLink.event)
              setData(toNull(null))
              setFiles(null)
              setIsEdit(false)
              setChangeLink({ isChange: false, event: null })
            } else {
              close()
            }
          }}
          close={() => setIsNotSave(false)}
          text="Are you sure you want to leave without saving changes?"
        />}
        <CustomModal
          title="Tile Menu - Tile"
          close={isCanClose}
          closeWithoutHide={true}
          cancelBtn="Cancel"
          submitBtn="Save"
          submit={sumbitType}
        >
          <div className='type-modal-type--content'>
            <div className='tile-modal__field'>
              <div className='tile-modal--subtitle'>Content Type</div>
              <CustomDropdown
                variant='grey'
                onChange={changeType}
                value={typeLink?.name}
                placeholder='Choose Content Type'
                options={typeTile === 'tile' ? optionTile : optionHero}
                variant='grey'
                serch={false}
              />
            </div>
            {renderType()}

          </div>
        </CustomModal>
      </div>
    </>
  )
}

export default ModalTileType