import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './space-tabs.scss';
import { Launching } from './FineTuningTabs/Launching';
import { Visibility } from './FineTuningTabs/Visibility.jsx';
import { UserInterface } from './FineTuningTabs/UserInterface';
import { GuidedTours } from './FineTuningTabs/GuidedTours';

export const FineTuningTab = ({
  spaceData,
  setSpaceData,
  edited,
  setEdited,
  handleCancelModal,
  closeModule,
  handleSuccessModal,
}) => {
  const [tab, setTab] = useState('Launching');
  const color = useSelector(state => state.profile.color);
  const launchingClassnames = `tuning-tab__tab ${
    tab === 'Launching' ? 'tuning-tab__tab_active' : ''
  }`;
  const visibilityClassnames = `tuning-tab__tab ${
    tab === 'Visibility' ? 'tuning-tab__tab_active' : ''
  }`;
  const userInterfaceClassnames = `tuning-tab__tab ${
    tab === 'User Interface' ? 'tuning-tab__tab_active' : ''
  }`;
  const guidedToursClassnames = `tuning-tab__tab ${
    tab === 'Guided tours' ? 'tuning-tab__tab_active' : ''
  }`;

  const renderTab = () => {
    switch (tab) {
      case 'Launching':
        return (
          <Launching
            spaceData={spaceData}
            setSpaceData={setSpaceData}
            edited={edited}
            setEdited={setEdited}
            handleCancelModal={handleCancelModal}
            closeModule={closeModule}
            handleSuccessModal={handleSuccessModal}
          />
        );
      case 'Visibility':
        return (
          <Visibility
            spaceData={spaceData}
            setSpaceData={setSpaceData}
            edited={edited}
            setEdited={setEdited}
            handleCancelModal={handleCancelModal}
            closeModule={closeModule}
            handleSuccessModal={handleSuccessModal}
          />
        );
      case 'User Interface':
        return (
          <UserInterface
            spaceData={spaceData}
            setSpaceData={setSpaceData}
            edited={edited}
            setEdited={setEdited}
            handleCancelModal={handleCancelModal}
            closeModule={closeModule}
            handleSuccessModal={handleSuccessModal}
          />
        );
      case 'Guided Tours':
        return (
          <GuidedTours
            spaceData={spaceData}
            setSpaceData={setSpaceData}
            edited={edited}
            setEdited={setEdited}
            handleCancelModal={handleCancelModal}
            closeModule={closeModule}
            handleSuccessModal={handleSuccessModal}
          />
        );
    }
  };

  return (
    <div className="space-tab tuning-tab">
      <h2 className="space-tab__title">{`${
        spaceData?.name || 'Space'
      } - Fine Tuning`}</h2>

      <div className="tuning-tab__tabs">
        <div
          className={launchingClassnames}
          onClick={() => setTab('Launching')}
          style={
            launchingClassnames.includes('active')
              ? { border: `1px solid ${color}`, color }
              : {}
          }
        >
          Launching
        </div>
        <div
          className={visibilityClassnames}
          onClick={() => setTab('Visibility')}
          style={
            visibilityClassnames.includes('active')
              ? { border: `1px solid ${color}`, color }
              : {}
          }
        >
          Visibility
        </div>
        <div
          className={userInterfaceClassnames}
          onClick={() => setTab('User Interface')}
          style={
            userInterfaceClassnames.includes('active')
              ? { border: `1px solid ${color}`, color }
              : {}
          }
        >
          User Interface
        </div>
        <div
          className={guidedToursClassnames}
          onClick={() => setTab('Guided Tours')}
          style={
            guidedToursClassnames.includes('active')
              ? { border: `1px solid ${color}`, color }
              : {}
          }
        >
          Guided Tours
        </div>
      </div>

      {renderTab()}
    </div>
  );
};
