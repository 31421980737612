import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomModal } from '../../../../components/CustomModal/CustomModal';
import { CustomDropdown } from '../../../../components/CustomDropdown/CustomDropdown';
import { CustomInput } from '../../../../components/CustomInput/CustomInput';
import { addPrettyUrl, addShowcaseLanguage } from '../../../../redux/actions';
import { updateSpaceConfig } from '../../../../crud/spaces/spaces';
import {
  showLoaderAction,
  hideLoaderAction,
  showSimpleModalAction,
} from '../../../../redux/actions';
import './modal.scss';
import { formsReducer, updateFormAction } from '../../../../helpers/formUtils';

const ModalSettingsSpace = ({ closeModal, current }) => {
  const [showcaseLanguageOptions, setShowcaseLanguageOptions] = useState([]);

  const dispatch = useDispatch();

  const showcaseLanguages = useSelector(
    state => state.profile.showcaseLanguages
  );

  const prettyUrls = useSelector(state => state.profile.prettyUrls);

  const showcaseDomain = useSelector(state => state.profile.showcase_domain);

  const [formData, setFormData] = useReducer(formsReducer, {
    lang: {
      value:
        showcaseLanguages.find(({ id }) => id === current.id)?.value ||
        current.lang ||
        'en',
      touched: false,
      hasError: true,
      error: '',
    },
    url: {
      value: prettyUrls.find(({ id }) => id === current.id)
        ? prettyUrls.find(({ id }) => id === current.id).value
        : current.pretty_url,
      touched: false,
      hasError: true,
      error: '',
    },
  });

  const handleCustomDropdownChange = option =>
    setFormData(
      updateFormAction({
        name: 'lang',
        value: option.value,
        hasError: false,
        error: '',
        touched: false,
        isFormValid: true,
      })
    );

  const handleCustomInputChange = e =>
    setFormData(
      updateFormAction({
        name: 'url',
        value: e.target.value,
        hasError: false,
        error: '',
        touched: false,
        isFormValid: true,
      })
    );

  const createLinkShowcase = (forRequest = false) => {
    const url =
      typeof formData.url.value === 'string' && formData.url.value.trimStart()
        ? formData.url.value.trimStart()
        : forRequest
        ? ''
        : `showcase/${current.id}/${current.url.split('/')[4]}${
            current.additionalposition ? `${current.additionalposition}` : ''
          }`;
    return `${showcaseDomain}/${url}`;
  };

  const handleSubmit = () => {
    dispatch(
      addShowcaseLanguage({
        id: current.id,
        ...showcaseLanguageOptions.find(
          ({ value }) => value === formData.lang.value
        ),
      })
    );
    const value =
      typeof formData.url.value === 'string' && formData.url.value.trimStart()
        ? formData.url.value.trimStart()
        : null;
    dispatch(addPrettyUrl({ id: current.id, value }));
    dispatch(showLoaderAction());
    updateSpaceConfig(current.id, {
      lang: formData.lang.value,
      prettyUrl: createLinkShowcase(true),
    })
      .then(() => {
        dispatch(hideLoaderAction());
        closeModal();
      })
      .catch(err => {
        const { error, message } = err?.response?.data;
        if (error === 'Not Found' && message?.includes('Url already exists')) {
          setFormData(
            updateFormAction({
              name: 'url',
              value: formData.url.value,
              hasError: true,
              error: message,
              touched: true,
              isFormValid: false,
            })
          );
        } else if (
          error === 'Bad Request' &&
          message?.[0].includes('lang must be a string')
        ) {
          setFormData(
            updateFormAction({
              name: 'lang',
              value: formData.lang.value,
              hasError: true,
              error: 'This field is required',
              touched: true,
              isFormValid: false,
            })
          );
        } else {
          dispatch(showSimpleModalAction({ title: 'Error', text: message }));
        }
      });
  };

  useEffect(() => {
    const fetchOptions = async () => {
      const options = await fetch(
        `${process.env.PUBLIC_URL}/showcaseLanguageOptions.json`
      ).then(r => r.json());
      setShowcaseLanguageOptions(options);
    };
    fetchOptions();
  }, []);

  return (
    <div className="manage-col-modal">
      <form onSubmit={handleSubmit}>
        <CustomModal
          title="Setting"
          close={closeModal}
          cancelBtn="Cancel"
          submitBtn="Save"
          submit={handleSubmit}
          customClassName="modal-settings-space"
        >
          <div className="manage-col-modal__main-container">
            <div className="form-item-container first">
              <div className="form-item-label">Showcase Language</div>
              <div className="form-item-dropdown">
                <CustomDropdown
                  variant="grey"
                  placeholder="Choose Language"
                  formData={formData.lang}
                  value={
                    showcaseLanguageOptions.find(
                      ({ value }) => value === formData.lang.value
                    )?.name
                  }
                  options={showcaseLanguageOptions}
                  onChange={handleCustomDropdownChange}
                  variantError="bottomleft"
                />
              </div>
            </div>
            <div className="form-item-container">
              <div className="form-item-label">Pretty URL</div>
              <div className="form-item-input">
                <CustomInput
                  placeholder="Enter custom pretty URL"
                  formData={formData.url}
                  onChange={handleCustomInputChange}
                  variantError="bottomleft"
                />
              </div>
            </div>
          </div>
        </CustomModal>
      </form>
    </div>
  );
};

export default ModalSettingsSpace;
