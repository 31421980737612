import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { CustomDropdown } from "../../../../../components/CustomDropdown/CustomDropdown";
import { SimpleInput } from "../../../../../components/CustomInput/SimpleInput";
import { getPoiList, updatePoi } from "../../../../../crud/spaces/spaces";
import { useIsMount } from "../../../../../hooks/useIsMount";
import {
  hideLoaderAction,
  setSpaceInsidePoi1,
  setSpaceOutsidePoi1,
  showLoaderAction,
  showSimpleModalAction,
} from "../../../../../redux/actions";
import eye from "../../../../../assets/icons/eye.svg";
import eyeCrossed from "../../../../../assets/icons/eye-crossed-out.svg";
import { useClickOutside } from "../../../../../hooks/useClickOutside";
import { fillFormAction, toNull } from "../../../../../helpers/formUtils";
import { useDebouncedCallback } from "use-debounce";

const MAX_HEIGHT = 50;
const MIN_HEIGHT = 10;
const MIN_WIDTH = 10;

export const PoiStep1 = ({
  spaceData,
  setSpaceData,
  edited,
  setEdited,
  handleCancelModal,
  closeModule,
  handleSuccessModal,
  setStep,
  setCurrent,
  setPoiEdit,
  openDeletePoiModal,
  showcase,
  updateStep1,
  setUpdateStep1,
  poiListOriginal,
  setPoiListOrigin,
  poiEdit,
}) => {
  const ref = useRef();
  const isFirstRender = useIsMount();
  const dispatch = useDispatch();
  const [search, setSearch] = useState();
  const [floor, setFloor] = useState({ name: "", value: "" });
  const [poiList, setPoiList] = useState([]);
  const [open, setOpen] = useState(null);
  const ObserverSweep = useRef(null);
  const [spaceDataObserver, setSpaceDataObserver] = useState({
    isActive: false,
  });
  const [settingSort, setSettingSort] = useState({
    orderDirection: "desc",
    order: "name",
  });

  useClickOutside(ref, () => {
    if (open) setOpen(false);
  });

  const updateTitle = () => {
    const prevValue = settingSort.orderDirection === "desc" ? "asc" : "desc";
    setSettingSort({
      orderDirection: prevValue,
      order: "name",
    });
  };

  const debouncedUpdatePoi = useDebouncedCallback(
    // function
    (item) => {
      const findInOriginal = poiListOriginal.find(
        (original) => original.matterPortId === item?.id
      );
      if (!findInOriginal) return;
      let mutate = poiEdit?.categories?.value?.reduce((arr, category) => {
        let item = `${category?.value}`;
        arr.push(item);
        return arr;
      }, []);
      const data = {
        stemVector: item.stemVector,
        x: item.anchorPosition.x,
        y: item.anchorPosition.y,
        z: item.anchorPosition.z,
        matterPortId: poiEdit?.matterPortId?.value,
        name: poiEdit?.name?.value,
        description: poiEdit?.description?.value,
        backgroundColor: poiEdit?.backgroundColor?.value,
        opacity: poiEdit?.opacity?.value,
        size: poiEdit?.size?.value,
        mediaType: poiEdit?.mediaType?.value,
        floor: poiEdit?.floor?.value,
        enabled: poiEdit?.enabled?.value,
        mouseAction: poiEdit?.mouseAction?.value,
        createdAt: poiEdit?.createdAt?.value,
        categories: mutate,
        modalSize: poiEdit?.modalSize?.value,
        stemVisible: poiEdit?.stemVisible?.value,
        stemLength: poiEdit?.stemLength?.value,
      };
      if (data?.modalSize) {
        if (data?.modalSize.height && data?.modalSize.width) {
        } else if (data?.modalSize.height) {
          data.modalSize.width = "auto";
        } else {
          data.modalSize.height = "auto";
        }
      }
      if (poiEdit?.mediaUrl?.value) {
        data.mediaUrl = poiEdit?.mediaUrl?.value;
      }
      updatePoi(spaceData.id, findInOriginal.id, data)
        .then((res) => {
          dispatch(hideLoaderAction());
          let update = res.data;
          let originPoi = poiListOriginal;
          let updateOriginalPoi = originPoi.map((poi) => {
            if (poi?.id === poiEdit?.id?.value) {
              return {
                ...update,
                matterPortId: poi?.matterPortId,
              };
            } else {
              return poi;
            }
          });
          setPoiListOrigin(updateOriginalPoi);
        })
        .catch((err) => {
          //const errors = err?.response?.data
          //const { error, message, statusCode } = errors
          //dispatch(showSimpleModalAction({ title: error, text: message }))
        });
    },
    // delay in ms
    1000
  );

  const updateCategory = () => {
    const prevValue = settingSort.orderDirection === "desc" ? "asc" : "desc";
    setSettingSort({
      orderDirection: prevValue,
      order: "category",
    });
  };

  const loadData = () => {
    dispatch(showLoaderAction());
    let poiOrigin = poiListOriginal;
    getPoiList(spaceData.id, {
      floor: floor.name,
      search,
      order: settingSort.order,
      orderDirection: settingSort.orderDirection,
    }).then((res) => {
      dispatch(hideLoaderAction());
      if (poiOrigin) {
        let getData = res.data?.map((poi) => {
          let findItem = poiOrigin.find((origin) => origin?.id == poi?.id);
          if (findItem?.matterPortId) {
            poi.matterPortId = findItem?.matterPortId;
          }
          return poi;
        });
        setPoiList(getData);
      } else {
        setPoiList(res.data.list);
      }
    });
  };

  useEffect(() => {
    loadData();
  }, [spaceData, floor, settingSort]);
  useEffect(() => {
    if (poiListOriginal?.length) {
      let poi = poiList;
      let originPoi = poiListOriginal;
      let getData = poiList?.map((poi) => {
        let findItem = originPoi.find((origin) => origin?.id == poi?.id);
        if (findItem?.matterPortId) {
          poi.matterPortId = findItem?.matterPortId;
        }
        return poi;
      });
      setPoiList(getData);
    }
  }, [poiListOriginal]);

  useEffect(() => {
    if (updateStep1 === "update") {
      loadData();
      setUpdateStep1(null);
    }
  }, [updateStep1]);

  const toggleClassForShowcase = () => {
    const container = document.querySelector(".space__iframe-container");
    container?.classList?.toggle("space__iframe-container--hide");
  };

  useEffect(() => {
    if (isFirstRender) return;
    const handler = setTimeout(() => {
      loadData();
    }, 1000);
    return () => clearTimeout(handler);
  }, [search]);
  const handleReset = () => {
    setPoiEdit(toNull(null));
  };

  useEffect(() => {
    toggleClassForShowcase();
    ObserverSweep.current = showcase?.Sweep?.current?.subscribe(function (
      currentSweep
    ) {
      // Change to the current sweep has occurred.
      if (currentSweep.sid === "") {
        handleReset();
      } else {
      }
    });
    dispatch(setSpaceInsidePoi1());
    showcase.Pointer.intersection.subscribe(function (intersection) {
      setSpaceDataObserver((prev) => ({
        ...prev,
        intersectionCache: intersection,
      }));
    });
    showcase.Camera.pose.subscribe(function (pose) {
      setSpaceDataObserver((prev) => ({
        ...prev,
        poseCache: pose,
      }));
    });
    showcase.Tag.data.subscribe({
      onUpdated(index, item, collection) {
        debouncedUpdatePoi(item);
      },
    });
    return () => {
      ObserverSweep.current.cancel();
      buttonHide();
      toggleClassForShowcase();
      dispatch(setSpaceOutsidePoi1());
    };
  }, []);
  const debouncedState = useDebouncedCallback(
    // function
    (value) => {
      const calculate = async () => {
        const iframe = document.getElementById("showcase-iframe");
        const size = {
          w: iframe.clientWidth,
          h: iframe.clientHeight,
        };
        buttonPositionInitial(spaceDataObserver.poseCache, size);
      };
      calculate();
    },
    // delay in ms
    10
  );

  const buttonHide = async () => {
    const btn = document.getElementById("drag");
    if (btn) {
      btn.remove();
    } else {
    }
  };

  useEffect(() => {
    if (poiEdit) {
      debouncedState();
    } else {
      buttonHide();
    }
    if (spaceDataObserver.isActive && poiEdit) {
      const position = spaceDataObserver.intersectionCache.position;
      const normal = spaceDataObserver.intersectionCache.normal;
      showcase.Tag.editPosition(poiEdit.matterPortId.value, {
        anchorPosition: {
          x: position?.x,
          y: position?.y,
          z: position?.z,
        },
        stemVector: {
          x: normal.x,
          y: normal.y,
          z: normal.z,
        },
      });
      showcase.Mattertag.editStem(poiEdit.matterPortId?.value, {
        stemHeight: poiEdit?.stemLength?.value,
      });
    }
  }, [poiEdit, spaceDataObserver]);

  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  function fromPercentageToValue(totalValue, percentage) {
    return (totalValue * percentage) / 100;
  }

  function perspective(procentH, procentW, delta) {
    const DELTA_AXES = delta;
    const deltaHeight = MAX_HEIGHT - MIN_HEIGHT;
    const VAR_Y = deltaHeight - DELTA_AXES;
    const getPxForHeight = fromPercentageToValue(VAR_Y, procentH);
    const getPxForWidth = getPxForHeight;
    return {
      w: getPxForWidth,
      h: getPxForHeight,
    };
  }

  const buttonPositionInitial = async (pose, size) => {
    const getTags = await showcase?.Mattertag.getData();
    const findTag = getTags.find(
      (item) => item.sid === poiEdit?.matterPortId?.value
    );
    if (!findTag) return;
    const currCoords = await showcase.Conversion.worldToScreen(
      findTag.anchorPosition,
      pose,
      size
    );
    const relativeCoord = {
      w: Math.abs(percentage(currCoords.x, size.w)),
      h: Math.abs(percentage(currCoords.y, size.h)),
    };
    const btn = document.getElementById("drag");
    if (btn) {
      const perspectiveH = perspective(relativeCoord.h, relativeCoord.w, 0);
      const calculateH = perspectiveH.h + MIN_HEIGHT;
      const calculateW = perspectiveH.w + MIN_WIDTH;
      btn.style.left = `${Math.abs(currCoords.x)}px`;
      btn.style.top = `${Math.abs(currCoords.y)}px`;
      btn.style.transform = `transform: translateY(-${
        calculateH / 2
      }px) translateX(-${calculateH / 2}px)`;
      btn.style.width = calculateW + "px";
      btn.style.height = calculateH + "px";
      btn.addEventListener("click", () => {
        setSpaceDataObserver((prev) => ({
          ...prev,
          isActive: !spaceDataObserver.isActive,
        }));
      });
      btn.addEventListener("mousedown", () => {
        setSpaceDataObserver((prev) => ({
          ...prev,
          isActive: !spaceDataObserver.isActive,
        }));
      });
    } else {
      const perspectiveH = perspective(relativeCoord.h, relativeCoord.w, 0);
      const calculateH = perspectiveH.h + MIN_HEIGHT;
      const calculateW = perspectiveH.w + MIN_WIDTH;
      const getShowcaseContainer = document.querySelector(
        ".space__iframe-container"
      );
      let createButton = document.createElement("button");
      createButton.classList.add("point-drag");
      createButton.setAttribute("id", "drag");
      createButton.style.left = `${Math.abs(currCoords.x)}px`;
      createButton.style.top = `${Math.abs(currCoords.y)}px`;
      createButton.style.width = calculateW + "px";
      createButton.style.height = calculateH + "px";
      createButton.style.transform = `translateY(-${
        calculateH / 2
      }px) translateX(-${calculateH / 2}px)`;
      getShowcaseContainer.appendChild(createButton);
      createButton.addEventListener("click", () => {
        setSpaceDataObserver((prev) => ({
          ...prev,
          isActive: !spaceDataObserver.isActive,
        }));
      });
      createButton.addEventListener("mousedown", () => {
        setSpaceDataObserver((prev) => ({
          ...prev,
          isActive: !spaceDataObserver.isActive,
        }));
      });
    }
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleChangeFloor = (value) => {
    setFloor(value);
  };

  const getFllors = () => {
    let arr = [];
    if (spaceData) {
      for (let i = 1; i <= spaceData.floors; i++) {
        arr.push({ name: i, value: i });
      }
    }
    return arr;
  };

  const handleChangeStatus = (poiId, elem) => {
    dispatch(showLoaderAction());
    const data = { ...elem };
    data.enabled = elem.enabled === true ? false : true;
    if (!data.mediaUrl) delete data.mediaUrl;
    let mutate = data?.categories?.reduce((arr, category) => {
      let item = `${category?.id}`;
      arr.push(item);
      return arr;
    }, []);
    data.categories = mutate;
    updatePoi(spaceData.id, poiId, data).then((res) => {
      dispatch(hideLoaderAction());
      loadData();
      let updateItem = poiListOriginal.map((poi) => {
        if (poi?.id === elem?.id) {
          poi.enabled = !poi?.enabled;
          return poi;
        } else {
          return poi;
        }
      });
      setPoiListOrigin(updateItem);
      showcase.Mattertag.editOpacity(
        elem?.matterPortId,
        !elem?.enabled ? elem?.opacity : 0
      )
        .then((edit) => {})
        .catch((err) => {});
    });
  };

  return (
    <>
      <h2 className="poi-tab__title">{`${
        spaceData?.name || "Space"
      } - POIs`}</h2>
      <h3 className="poi-tab__subtitle">
        Navigate to the desired location and click the <br />+ button the pin
        the POI.
      </h3>

      <CustomDropdown
        placeholder="Floor"
        variant="grey"
        serch={false}
        value={floor.name}
        onChange={handleChangeFloor}
        options={getFllors()}
      />

      <div className="poi-tab__input-container">
        <SimpleInput
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          isSearch={true}
        />
      </div>

      <div className="poi-tab__table">
        <div className="poi-tab__table-head poi-tab__table-row">
          <div />
          <div className="poi-tab__table-item" onClick={updateTitle}>
            Title
          </div>
          <div
            className="poi-tab__table-item"
            onClick={updateCategory} /*className='poi-tab__table-category'*/
          >
            Category
          </div>
          <div />
        </div>

        {poiList.length > 0 ? (
          <div className="poi-tab__table-body">
            {poiList.map((elem, index) => {
              return (
                <div className="poi-tab__table-row">
                  <div className="poi-tab__table-cell">
                    <img
                      src={elem.enabled ? eye : eyeCrossed}
                      className={`poi-tab__table-eye ${
                        elem.enabled ? "" : "poi-tab__table-eye_crossed"
                      }`}
                      onClick={() => handleChangeStatus(elem.id, elem)}
                    />
                  </div>
                  <div
                    className={`poi-tab__table-cell ${
                      elem.enabled ? "" : "poi-tab__table-cell_disabled"
                    }`}
                  >
                    {elem.name || "no name"}
                  </div>
                  <div
                    className={`poi-tab__table-cell ${
                      elem.enabled ? "" : "poi-tab__table-cell_disabled"
                    }`}
                  >
                    {elem?.categories?.reduce((prevStr, category) => {
                      return prevStr + category?.title + " ";
                    }, "")}
                  </div>
                  <div
                    className={`poi-tab__table-cell ${
                      elem.enabled ? "" : "poi-tab__table-cell_disabled"
                    }`}
                    style={{ position: "relative" }}
                  >
                    <div
                      className={`poi-tab__dots ${
                        elem.enabled ? "" : "poi-tab__dots_disabled"
                      }`}
                      onClick={() => setOpen(elem.id)}
                    >
                      <div className="poi-tab__dot" />
                      <div className="poi-tab__dot" />
                      <div className="poi-tab__dot" />
                    </div>
                    {open === elem.id && (
                      <div
                        className={`poi-tab__collapse ${
                          poiList.length - (index + 1) <= 1 &&
                          poiList.length > 3
                            ? "poi-tab__collapse--top"
                            : ""
                        }`}
                        ref={ref}
                      >
                        <div
                          className="poi-tab__collapse-option"
                          onClick={() => {
                            setOpen(false);
                            setPoiEdit(toNull(null));
                            //  showcase?.Tag.allowAction(elem?.matterPortId, {
                            //    opening: false,
                            //  });

                            showcase?.Mattertag?.navigateToTag(
                              elem?.matterPortId,
                              showcase.Mattertag.Transition.FLY
                            )
                              .then((res) => {})
                              .catch((err) => {
                                console.log(err);
                              });
                          }}
                        >
                          View
                        </div>
                        <div
                          className="poi-tab__collapse-option"
                          onClick={() => {
                            setStep(2);
                            setCurrent(elem);
                            setOpen(false);
                            let mutate = elem?.categories?.reduce(
                              (arr, category) => {
                                let item = {
                                  name: category?.title,
                                  value: category?.id,
                                };
                                arr.push(item);
                                return arr;
                              },
                              []
                            );
                            setPoiEdit(
                              fillFormAction({
                                name: {
                                  value: elem?.name,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                description: {
                                  value: elem?.description,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                backgroundColor: {
                                  value: elem?.backgroundColor,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                opacity: {
                                  value: elem?.opacity,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                size: {
                                  value: elem?.size,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                categories: {
                                  value: mutate || [],
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                mediaType: {
                                  value: elem?.mediaType,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                mediaUrl: {
                                  value: elem?.mediaUrl,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                mouseAction: {
                                  value: elem?.mouseAction,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                matterPortId: {
                                  value: elem?.matterPortId,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                id: {
                                  value: elem?.id,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                isFormValid: false,
                                x: {
                                  value: elem?.x,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                y: {
                                  value: elem?.y,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                z: {
                                  value: elem?.z,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                floor: {
                                  value: elem?.floor,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                createdAt: {
                                  value: elem?.createdAt,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                enabled: {
                                  value: elem?.enabled,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                image: {
                                  value: { origin: elem?.image },
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                icon: {
                                  value: elem?.icon,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                modalSize: {
                                  value: elem?.modalSize,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                stemVector: {
                                  value: elem?.stemVector,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                stemVisible: {
                                  value: elem?.stemVisible,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                                stemLength: {
                                  value: elem?.stemLength,
                                  touched: false,
                                  hasError: true,
                                  error: "",
                                },
                              })
                            );
                            //  showcase?.Tag.allowAction(elem?.matterPortId, {
                            //    opening: false,
                            //  });
                            showcase?.Mattertag?.navigateToTag(
                              elem?.matterPortId,
                              showcase.Mattertag.Transition.FLY
                            )
                              .then((res) => {})
                              .catch((err) => {
                                console.log(err);
                              });
                          }}
                        >
                          Edit
                        </div>
                        <div
                          className="poi-tab__collapse-option"
                          onClick={() => {
                            openDeletePoiModal(elem);
                            setOpen(false);
                          }}
                        >
                          Delete
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="poi-tab__table-cell poi-tab__table-cell-nodata">
            There are no POIs
          </div>
        )}
      </div>
    </>
  );
};
