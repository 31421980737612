import {
    SIGN_IN,
    SET_ME,
    SHOW_LOADER,
    HIDE_LOADER,
    SET_LANGUAGE,
    ADD_SHOWCASE_LANGUAGE,
    ADD_PRETTY_URL,
    SHOW_ERROR_MODAL,
    HIDE_ERROR_MODAL,
    SET_IS_SPACE_READY,
    SET_IS_SPACE_NOT_READY, SET_IS_SPACE_OUTSIDE_PO1, SET_IS_SPACE_INSIDE_PO1,
    SET_CONFIGS_BY_DOMAIN
} from './constantTypes'

export const signInAction = data => {
    return {
        type: SIGN_IN,
        payload: data
    }
};

export const showLoaderAction = () => {
    return {
        type: SHOW_LOADER
    }
};

export const hideLoaderAction = () => {
    return {
        type: HIDE_LOADER
    }
};

export const setLanguage = (lang) => {
    return {
        type: SET_LANGUAGE,
        payload: lang
    }
};

export const addShowcaseLanguage = (lang) => {
    return {
        type: ADD_SHOWCASE_LANGUAGE,
        payload: lang
    }
}

export const addPrettyUrl = (url) => {
    return {
        type: ADD_PRETTY_URL,
        payload: url
    }
}

export const showSimpleModalAction = ({ title, text }) => {
    return {
        type: SHOW_ERROR_MODAL,
        payload: { title, text },
    }
};

export const hideSimpleModalAction = () => {
    return {
        type: HIDE_ERROR_MODAL,
    }
};

export const setMe = (payload) => {
    return {
        type: SET_ME,
        payload
    }
};

export const setConfigsByDomain = (payload) => {
    return {
        type: SET_CONFIGS_BY_DOMAIN,
        payload
    }
}

export const setSpaceReady = () => {
    return {
        type: SET_IS_SPACE_READY
    }
};

export const setSpaceNotReady = () => {
    return {
        type: SET_IS_SPACE_NOT_READY
    }
};

export const setSpaceInsidePoi1 = () => {
    return {
        type: SET_IS_SPACE_INSIDE_PO1
    }
};

export const setSpaceOutsidePoi1 = () => {
    return {
        type: SET_IS_SPACE_OUTSIDE_PO1
    }
};