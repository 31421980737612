// auth
export const SIGN_IN = 'SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const SET_ME = 'SET_ME';
export const SET_CONFIGS_BY_DOMAIN = 'SET_CONFIGS_BY_DOMAIN';

//helpers
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL';
export const HIDE_ERROR_MODAL = 'HIDE_ERROR_MODAL';

// language
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const ADD_SHOWCASE_LANGUAGE = 'ADD_SHOWCASE_LANGUAGE';

// pretty URL
export const ADD_PRETTY_URL = 'ADD_PRETTY_URL';

// SDK space is ready

export const SET_IS_SPACE_READY = 'SET_IS_SPACE_READY'
export const SET_IS_SPACE_NOT_READY = 'SET_IS_SPACE_NOT_READY'
export const SET_IS_SPACE_INSIDE_PO1 = 'SET_IS_SPACE_INSIDE_PO1'
export const SET_IS_SPACE_OUTSIDE_PO1 = 'SET_IS_SPACE_OUTSIDE_PO1'
