import {
    SHOW_LOADER,
    HIDE_LOADER,
    SHOW_ERROR_MODAL,
    HIDE_ERROR_MODAL,
    SET_IS_SPACE_READY,
    SET_IS_SPACE_NOT_READY, SET_IS_SPACE_INSIDE_PO1, SET_IS_SPACE_OUTSIDE_PO1
} from '../constantTypes'

const initialState = {
    loader: false,
    simpleModal: { isModal: false, title: '', text: '' },
    isSpaceReady : false,
    isSpaceInsidePoi1 : false
};

export const helperReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOADER:
            return {
                ...state,
                loader: true
            }
        case HIDE_LOADER:
            return {
                ...state,
                loader: false
            }
        case SHOW_ERROR_MODAL:
            return {
                ...state,
                simpleModal: {
                    isModal: true,
                    title: action.payload.title,
                    text: action.payload.text,
                }
            }
        case HIDE_ERROR_MODAL:
            return {
                ...state,
                simpleModal: {
                    isModal: false,
                    title: '',
                    text: '',
                }
            }
        case SET_IS_SPACE_READY :
            return {
                ...state,
                isSpaceReady: true
            }
        case SET_IS_SPACE_NOT_READY :
            return {
                ...state,
                isSpaceReady: false
            }
        case SET_IS_SPACE_INSIDE_PO1 :
            return {
                ...state,
                isSpaceInsidePoi1: true
            }
        case SET_IS_SPACE_OUTSIDE_PO1 :
            return {
                ...state,
                isSpaceInsidePoi1: false
            }
        default: return state
    }
};
