import React, { useReducer, useRef, useState } from 'react';
import Chrome from 'react-color/lib/components/chrome/Chrome';
import rgbHex from 'rgb-hex';
import { useDispatch, useSelector } from 'react-redux';
import { CustomInput } from '../../../components/CustomInput/CustomInput';
import { formsReducer, updateFormAction } from '../../../helpers/formUtils';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { onInputChange } from '../../../helpers/formUtils';
import { CustomUploadImage } from '../../../components/CustomUploadImage/CustomUploadImage';
import { CustomButton } from '../../../components/CustomButton/CustomButton';
import {
  hideLoaderAction,
  showLoaderAction,
  showSimpleModalAction,
} from '../../../redux/actions';
import { updateCustomization } from '../../../crud/customize';
import '../home.scss';
import './customize-page.scss';
import '../../../components/CustomIconPicker/CustomIcon.scss';

const formFields = [
  'domain',
  'showcase_domain',
  'color',
  'logo',
  'favicon',
  'matterport_secret',
  'matterport_id',
  'matterport_sdk_key',
  'title',
];

export const CustomizePage = ({ pageTitle }) => {
  const profile = useSelector(state => state.profile);

  const initialState = formFields.reduce(
    (formData, formField) => ({
      ...formData,
      [formField]: {
        value: profile[formField] ?? '',
        touched: false,
        hasError: true,
        error: '',
      },
    }),
    {}
  );

  const [formData, setFormData] = useReducer(formsReducer, initialState);

  const [openColorPicker, setOpenColorPicker] = useState(false);

  const colorPicker = useRef(null);

  useClickOutside(colorPicker, () => setOpenColorPicker(false));

  const dispatch = useDispatch();

  const handleColorChange = color =>
    onInputChange(
      'color',
      '#' + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a),
      setFormData,
      formData
    );

  const handleInputChange = (name, e) =>
    setFormData(
      updateFormAction({
        name,
        value: e.target.value,
        hasError: false,
        error: '',
        touched: false,
        isFormValid: true,
      })
    );

  const handleSubmit = e => {
    e.preventDefault();
    const data = new FormData();
    formFields.forEach(formField => {
      data.append(formField, formData[formField].value);
    });
    dispatch(showLoaderAction());
    updateCustomization(data)
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
        dispatch(
          showSimpleModalAction({
            title: 'Error',
            text: err?.response?.data.message,
          })
        );
      })
      .finally(() => {
        dispatch(hideLoaderAction());
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="subheader">
        <h2 className="subheader__title">{pageTitle}</h2>
      </div>
      <div className="customize-page__left-right-container">
        <div className="customize-page__left-container">
          <div className="customize-page__label">Domain Workshop</div>
          <CustomInput
            placeholder="https://"
            formData={formData.domain}
            onChange={e => handleInputChange('domain', e)}
            variantError="bottomleft"
          />
        </div>
        <div>
          <div className="customize-page__label">Color</div>
          <div className="customize-page__color-container">
            <div className="customize-page__hex">
              {formData?.color?.value || '#2b78e4'}
            </div>
            <div
              onClick={e => {
                e.stopPropagation();
                setOpenColorPicker(true);
              }}
              className="custom-icon__color"
              style={{
                backgroundColor: `${formData?.color?.value || '#2b78e4'}`,
              }}
            ></div>
            {openColorPicker && (
              <div ref={colorPicker} className="customize-page__picker">
                <Chrome
                  disableAlpha={false}
                  color={formData?.color?.value}
                  onChange={handleColorChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="customize-page__left-container">
        <div className="customize-page__label">Domain Showcase</div>
        <CustomInput
          placeholder="https://"
          formData={formData.showcase_domain}
          onChange={e => handleInputChange('showcase_domain', e)}
          variantError="bottomleft"
        />
      </div>
      <div className="customize-page__left-container">
        <div className="customize-page__label">Title</div>
        <CustomInput
          placeholder="Enter custom title"
          formData={formData.title}
          onChange={e => handleInputChange('title', e)}
          variantError="bottomleft"
        />
      </div>
      <CustomUploadImage
        formData={formData}
        setFormData={setFormData}
        label="Logo"
      />
      <CustomUploadImage
        formData={formData}
        setFormData={setFormData}
        label="Favicon"
      />
      <div className="customize-page__left-right-container">
        <div className="customize-page__left-container">
          <div className="customize-page__label">Matterport Secret</div>
          <CustomInput
            placeholder="Enter your Matterport secret"
            formData={formData.matterport_secret}
            onChange={e => handleInputChange('matterport_secret', e)}
            variantError="bottomleft"
          />
        </div>
        <div className="customize-page__right-container">
          <div className="customize-page__label">Matterport ID</div>
          <CustomInput
            placeholder="Enter your Matterport ID"
            formData={formData.matterport_id}
            onChange={e => handleInputChange('matterport_id', e)}
            variantError="bottomleft"
          />
        </div>
      </div>
      <div className="customize-page__last-input-container">
        <div className="customize-page__label">Matterport SDK Key</div>
        <CustomInput
          placeholder="Enter your Matterport SDK key"
          formData={formData.matterport_sdk_key}
          onChange={e => handleInputChange('matterport_sdk_key', e)}
          variantError="bottomleft"
        />
      </div>
      <div className="customize-page__submit-btn">
        <CustomButton
          variant="green"
          name="Save"
          onClick={handleSubmit}
          type="submit"
        />
      </div>
    </form>
  );
};
