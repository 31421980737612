import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CustomButton } from '../../../../components/CustomButton/CustomButton';
import { ImageComponent } from '../../../../components/ImageComponent/ImageComponent';
import { setPhotoAndLocation } from '../../../../crud/spaces/spaces';
import { hideLoaderAction, showLoaderAction } from '../../../../redux/actions';
import { ReactComponent as Eye } from '../../../../assets/icons/eye.svg';
import { dataURLtoFile } from '../../../../helpers/base64ToFile';

export const StartingLocation = ({ showcase,spaceData, setSpaceData }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [toolTip, setTooltip] = useState(false);
    const [updateImg, setUpdateImg] = useState(false);
    const [photoHovered, setPhotoHovered] = useState(false);
    // const []
  async function createDeepLink(){
    await showcase.Link.setShareLinkPolicy(showcase.Link.CreationPolicy.REFERRER);
    let link =  await showcase.Link.createDeepLink();
    let getParams = new URL(link);
    const searchParams = new URLSearchParams(getParams.search);
    let sm = searchParams.get("sm");
    let sr = searchParams.get("sr");
    let sp = searchParams.get("sp");
    let sf = searchParams.get("sf");
    let ss = searchParams.get("ss");
    const createParams = () =>{
      let paramsString = ''
      if(sm !== null){
        paramsString = paramsString + `&sm=${sm}`
      }else{}
      if(sr !== null){
        paramsString = paramsString + `&sr=${sr}`
      }else{}
      if(sp !== null){
        paramsString = paramsString + `&sp=${sp}`
      }else{}
      if(sf !== null){
        paramsString = paramsString + `&sf=${sf}`
      }else{}
      if(ss !== null){
        paramsString = paramsString + `&ss=${ss}`
      }else{}
      return paramsString
    }
    return createParams()
  }
  const findSweep = async (sweepId) =>{
    let {sweeps} = await showcase.Model.getData();
    let filter = sweeps.filter(sweep => sweep.uuid === sweepId);
    if(filter.length > 0){
      return filter[0].position.x
    }else{
      return ''
    }
  }
   const goToSweep = async () =>{
    let getMode = spaceData.mode;
    let getSweep = spaceData.sweep;
    let getRotation = spaceData.rotation;
    let getPosition = spaceData.position;
    if(!getSweep || !getMode || !getPosition | !getRotation) return;
    let {sweeps} = await showcase.Model.getData();
    let findSweep = sweeps.find(sweep=>sweep.position.x == getSweep);
    const transition = showcase.Sweep.Transition.FLY;
    const transitionMode = showcase.Mode.TransitionType.FLY;
    await showcase.Sweep.moveTo(findSweep.sid,{
      rotation: getRotation,
      transition: transition,
    })
     await showcase.Mode.moveTo(getMode,{
       position : getPosition,
       rotation : getRotation,
     })

  }

    const onSubmit = async() => {
        if (showcase) {
            dispatch(showLoaderAction());
            let deepLink = await createDeepLink();
            let currentLocation = await showcase.Camera.getPose();
            let sweep = await findSweep(currentLocation.sweep);
            const resolution = {
              width: 1968,
              height: 688,
            };
            let screenshotFile = await showcase.Camera.takeScreenShot(resolution);
            const formData = new FormData();
            formData.set('file', dataURLtoFile(screenshotFile, 'screenshot'));
            formData.append('label', '');
            formData.append('zoom', '1');
            formData.append('viewMode', 'photo2D');
            formData.append('position', JSON.stringify(currentLocation.position));
            formData.append('rotation', JSON.stringify(currentLocation.rotation));
            formData.append('isStarting', true);
            formData.append('additionalPosition',deepLink);
            formData.append('mode',currentLocation.mode);
            formData.append('sweep',sweep);
            try {
              await setPhotoAndLocation(location.pathname.split('/')[3], formData)
              dispatch(hideLoaderAction());
              setUpdateImg(!updateImg)
              setSpaceData(prev=>({...prev,
                mode: currentLocation.mode,
                sweep,
                rotation: currentLocation.rotation,
                position: currentLocation.position
              }))
            }catch(e){
              dispatch(hideLoaderAction());
            }
        }
    };

    return (
        <div className='starting-location-tab'>
            <div
                className='starting-location-tab__photo-wrapper'
                onMouseOver={() => setPhotoHovered(true)}
                onMouseLeave={() => setPhotoHovered(false)}
            >
                <ImageComponent src={`admin/spaces/${location.pathname.split('/')[3]}/preview?mode=prev`} forceUpdate={updateImg} />

                {photoHovered && (
                    <>
                        <div onClick={goToSweep} className='starting-location-tab__photo-background' />
                        <div onClick={goToSweep} className='starting-location-tab__photo-eye'>
                            <Eye fill='#A3D164' />
                        </div>
                        <div onClick={goToSweep} className='starting-location-tab__photo-text'>Click to preview</div>
                    </>
                )}
            </div>

            <div className='starting-location-tab__button-wrapper'>
                <div className='starting-location-tab__set-location-button'>
                    <div className={`starting-location-tab__tooltip ${toolTip ? 'starting-location-tab__tooltip_active' : ''}`}>
                        Set current location as <br />
                        starting location
                    </div>

                    <CustomButton
                        name="Set Starting Location"
                        onMouseOver={() => setTooltip(true)}
                        onMouseLeave={() => setTooltip(false)}
                        onClick={onSubmit}
                        disabled={!showcase}
                    />
                </div>
            </div>
        </div>
    )
};
