import {
    SIGN_IN,
    SIGN_UP,
    SET_LANGUAGE,
    ADD_SHOWCASE_LANGUAGE,
    ADD_PRETTY_URL,
    SET_ME,
    SET_CONFIGS_BY_DOMAIN,
} from '../constantTypes'

const initialState = {
    language: {
        name: "English",
        value: "en"
    },
    showcaseLanguages: [],
    prettyUrls: [],
    id: null,
    number: null,
    fname: null,
    lname: null,
    email: null,
    verified: null,
    logoutTime: null,
    recoveryToken: null,
    role: null,
    createdAt: null,
    deletedAt: null,
    verifySend: null,
    jobTitle: null,
    company: null,
    country: null,
    city: null,
    street: null,
    zip: null,
    phoneNumber: null,
    phonePrefix: null,
    active: null,
    domain: null,
    showcase_domain: process.env.REACT_APP_SHOWCASE,
    color: null,
    logo: null,
    favicon: null,
    matterport_id: null,
    matterport_sdk_key: process.env.REACT_APP_MATTERPORT_SDK_KEY,
    matterport_secret: null,
    title: '',
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN:
            return { ...state, ...action.payload }
        case SET_LANGUAGE:
            return { ...state, language: action.payload }
        case ADD_SHOWCASE_LANGUAGE:
            return {
                ...state,
                showcaseLanguages: [
                    ...state.showcaseLanguages.filter(elem => elem.id !== action.payload.id),
                    action.payload
                ]
            }
        case ADD_PRETTY_URL:
            return {
                ...state,
                prettyUrls: [
                    ...state.prettyUrls.filter(elem => elem.id !== action.payload.id),
                    action.payload
                ]
            }
        case SET_ME:
            const { payload } = action;
            let setMeData = {};
            if (payload?.configs) {
                let { color, domain, showcase_domain, favicon, logo, matterport_sdk_key, title, ...rest } = payload?.configs;
                if (color) setMeData.color = color;
                if (domain && domain !== 'null') {
                    setMeData.domain = domain.includes('https://') ? domain : `https://${domain}`;
                }
                if (showcase_domain && showcase_domain !== 'null') {
                    setMeData.showcase_domain = showcase_domain.includes('https://')
                        ? showcase_domain
                        : `https://${showcase_domain}`;
                }
                if (favicon) setMeData.favicon = `${process.env.REACT_APP_API_URL}/${favicon}`;
                if (logo) setMeData.logo = `${process.env.REACT_APP_API_URL}/${logo}`;
                if (matterport_sdk_key && matterport_sdk_key !== 'null') {
                    setMeData.matterport_sdk_key = matterport_sdk_key;
                }
                if (title) setMeData.title = title;
                setMeData = { ...setMeData, ...rest };
                delete payload?.configs;
            }
            return {
                ...state,
                ...payload,
                ...setMeData,
                language: {
                    name: "English",
                    value: "en"
                },
            }
        case SET_CONFIGS_BY_DOMAIN:
            const { color, favicon, logo, title } = action.payload;
            let setConfigsByDomainData = {};
            if (color) setConfigsByDomainData.color = color;
            if (favicon) setConfigsByDomainData.favicon = `${process.env.REACT_APP_API_URL}/${favicon}`;
            if (logo) setConfigsByDomainData.logo = `${process.env.REACT_APP_API_URL}/${logo}`;
            if (title) setConfigsByDomainData.title = title;
            return { ...state, ...setConfigsByDomainData }
        default: return state;
    }
};
