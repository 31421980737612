import React, { useState, useEffect, useRef } from 'react';
import { CustomSwitcher } from '../../../../components/CustomSwitcher/CustomSwitcher';
import { CustomDropdown } from '../../../../components/CustomDropdown/CustomDropdown';
import { CustomButton } from '../../../../components/CustomButton/CustomButton';
import ModalOrderPlan from '../../Modals/ModalOrderPlan/ModalOrderPlan';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideLoaderAction,
  showLoaderAction,
  showSimpleModalAction,
} from '../../../../redux/actions';
import { ReactComponent as Upload } from '../../../../assets/icons/cloud-upload.svg';
import defaultIcon from '../../../../assets/icons/icon.svg';
import Draggable from 'react-draggable';
import {
  deleteImageForMinimap,
  getImageForMinimap,
  getMiniMap,
  updateMiniMap,
  updateShowMiniMap,
  uploadMinimapPhoto,
} from '../../../../crud/spaces/spaces';
import CustomSlider from '../../../../components/CustomSlider/CustomSlider';

const switchers = [
  {
    title: <></>,
    text: 'Show Map',
    fieldName: 'showMap',
  },
];

const MiniMap = ({
  spaceData,
  closeModule,
  setEditModalCansel,
  showcase,
  setEdited,
  edited,
  setUpdateMiniMap,
  poiList,
}) => {
  const [formData, setFormData] = useState([]);
  const zoom = useRef(null);
  const [isShowMap, setIsShowMap] = useState(!!spaceData.showMinimap);
  const [floor, setFloor] = useState({ name: '', value: '' });
  const [options, setOptions] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [positionDrag, setPositionDrag] = useState({
    x: 0,
    y: 0,
  });
  const [poiMapSetting, setPoiMapSetting] = useState({
    width: 1,
    height: 1,
    posWidth: 0,
    posHeight: 0,
  });
  const [files, setFiles] = useState([]);
  const [isDeleteImg, setIsDeleteImg] = useState(false);
  const [renderMap, setRenderMap] = useState({
    poi: [],
    sweep: [],
  });
  const dispatch = useDispatch();
  const color = useSelector(state => state.profile.color);

  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  function toPixel(totalValue, percentage) {
    return (totalValue * percentage) / 100;
  }

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);
  useEffect(() => {
    setTimeout(() => {
      parseToProcent(floor?.name);
    }, 100);
  }, [poiMapSetting]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png', '.jpeg', '.jpg', '.svg'],
      'image/jpeg': [],
      'image/jpg': [],
      'image/svg+xml': [],
    },
    maxSize: 15128640,
    maxFiles: 1,
    onDrop: acceptedFiles => {
      if (!acceptedFiles.length) return;
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            isCustom: true,
          })
        )
      );
      setIsDeleteImg(false);
      setEdited(true);
    },
    onDropRejected: file => {
      let message = file[0].errors[0].message;
      let title = file[0].errors[0].code;
      if (title === 'file-too-large') {
        title = 'Error';
        message = 'File is larger than 15MB';
      } else if (title === 'file-invalid-type') {
        title = 'Error';
        message = 'File type must be jpeg, png, jpg, svg';
      }
      dispatch(showSimpleModalAction({ title: title, text: message }));
    },
  });

  const handleSwitch = e => {
    let value = e.target.checked;
    setEdited(true);
    setIsShowMap(prev => value);
  };
  const handleChangeFloor = async value => {
    setEdited(false);
    setFloor(value);
    setPositionDrag({ x: 0, y: 0 });
    getImg(value?.info?.minimapId, value?.info?.floor);
    let sett = value?.info?.setting;
    if (sett) {
      setPoiMapSetting({
        width: sett.width,
        height: sett.height,
        posWidth: sett.posWidth,
        posHeight: sett.posHeight,
      });
      setTimeout(() => {
        const { width, height } = getZoomedWidth();
        const pixelWidth = toPixel(sett.posWidth, width);
        const pixelHeight = toPixel(sett.posHeight, height);
        setPositionDrag({ x: pixelWidth, y: pixelHeight });
      }, 500);
    } else {
      setPoiMapSetting({
        width: 1,
        height: 1,
        posWidth: 0,
        posHeight: 0,
      });
      setPositionDrag({ x: 0, y: 0 });
    }
  };
  const getFllors = miniMap => {
    let arr = [];
    if (miniMap.length) {
      for (let i = 0; i < miniMap.length; i++) {
        arr.push({
          name: miniMap[i]?.floor,
          value: miniMap[i]?.floor,
          info: miniMap[i],
          state: miniMap[i].state,
        });
      }
    }
    return arr;
  };
  const loadData = initial => {
    getMiniMap(spaceData.id)
      .then(res => {
        let miniMap = res.data;
        setFormData(miniMap);
        let options = getFllors(miniMap);
        setOptions(options);
        if (initial === 'initial' && options?.length > 0) {
          setFloor(options[0]);
          let idForImg = options[0].info?.minimapId;
          let floor = options[0].info?.floor;
          let sett = options[0].info?.setting;
          getImg(idForImg, floor, sett, miniMap);
          if (sett) {
            setPoiMapSetting({
              width: sett.width,
              height: sett.height,
              posWidth: sett.posWidth,
              posHeight: sett.posHeight,
            });
          } else {
            setPoiMapSetting({
              width: 1,
              height: 1,
              posWidth: 0,
              posHeight: 0,
            });
            setPositionDrag({ x: 0, y: 0 });
          }
        }
      })
      .catch(err => {
        const errors = err?.response?.data;
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: error, text: message }));
      })
      .finally(() => {
        dispatch(hideLoaderAction());
      });
  };

  function getZoomedWidth() {
    const getElementZoom = zoom.current;
    const widthZoomElement = getElementZoom?.offsetWidth;
    const heightElement = getElementZoom?.offsetHeight;
    return { width: widthZoomElement, height: heightElement };
  }

  const submitForm = () => {
    const { width, height } = getZoomedWidth();
    let update = {
      ...floor?.info,
      setting: {
        width: poiMapSetting.width,
        height: poiMapSetting.height,
        posWidth: percentage(poiMapSetting.posWidth, width),
        posHeight: percentage(poiMapSetting.posHeight, height),
      },
    };
    dispatch(showLoaderAction());
    updateShowMiniMap(spaceData?.id, { showMinimap: isShowMap });
    updateMiniMap(spaceData?.id, floor?.info?.floor, update)
      .then(res => {
        setUpdateMiniMap(true);
        setTimeout(() => {
          dispatch(showLoaderAction());
          loadData();
        }, 1000);
        if (!files[0]?.type) {
          edited
            ? dispatch(showSimpleModalAction({ title: 'Changes saved' }))
            : null;
          setEdited(false);
        } else if (!(files.length === 0) && isDeleteImg) {
          edited
            ? dispatch(showSimpleModalAction({ title: 'Changes saved' }))
            : null;
          setEdited(false);
          //dispatch(showSimpleModalAction({title:'Changes saved'}))
        }
      })
      .catch(err => {
        const errors = err?.response?.data;
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: error, text: message }));
      })
      .finally(() => {
        dispatch(hideLoaderAction());
      });
    if (files[0]?.type) {
      uploadImg();
    }
    if (isDeleteImg) {
      let id = floor?.info?.id;
      id ? deleteImg(id) : null;
    }
  };

  const getImgFloor = (floor, data) => {
    let findFloor;
    if (data) {
      findFloor = data.find(form => form.floor == floor);
    } else {
      findFloor = formData.find(form => form.floor == floor);
    }
    if (!findFloor) return;
    let result = { preview: findFloor.image, isCustom: false };
    setFiles([result]);
  };

  const parseToProcent = async floor => {
    if (!spaceData && !spaceData.minimapSettings) return;
    const currSettingMinimap = spaceData.minimapSettings[0];
    if (!currSettingMinimap) return;
    let { sweeps } = await showcase.Model.getData();
    let sortSweeps = sweeps
      .filter(sweep => sweep.floor + 1 === floor)
      .map(sweep => {
        let px =
          ((sweep.position.x - currSettingMinimap.image_origin_x) *
            currSettingMinimap.resolution_ppm) /
          currSettingMinimap.width;
        px = px * 100 * poiMapSetting.width + '%';
        let py =
          ((sweep.position.z * -1 - currSettingMinimap.image_origin_y) *
            currSettingMinimap.resolution_ppm) /
          currSettingMinimap.height;
        py = py * 100 * poiMapSetting.height + '%';
        sweep.px = {
          right: px,
          bottom: py,
        };
        return sweep;
      });
    let sortPoi = poiList
      .filter(poi => poi.floor === floor)
      .map(poi => {
        let px =
          ((poi.x - currSettingMinimap.image_origin_x) *
            currSettingMinimap.resolution_ppm) /
          currSettingMinimap.width;
        px = px * 100 * poiMapSetting.width + '%';
        let py =
          ((poi.z * -1 - currSettingMinimap.image_origin_y) *
            currSettingMinimap.resolution_ppm) /
          currSettingMinimap.height;
        py = py * 100 * poiMapSetting.height + '%';
        poi.px = {
          right: px,
          bottom: py,
        };
        return poi;
      });
    setRenderMap({
      poi: sortPoi,
      sweep: sortSweeps,
    });
  };

  const getImg = (id, floor, setting, data) => {
    parseToProcent(floor);
    if (!id) {
      getImgFloor(floor, data);
      if (!setting) return;
      const { width, height } = getZoomedWidth();
      const pixelWidth = toPixel(setting.posWidth, width);
      const pixelHeight = toPixel(setting.posHeight, height);
      setPositionDrag({ x: pixelWidth, y: pixelHeight });
      return;
    }
    dispatch(showLoaderAction());
    getImageForMinimap(id)
      .then(res => {
        if (res.data?.size === 0) {
          getImgFloor(floor, data);
          return;
        }
        const reader = new window.FileReader();
        reader.readAsDataURL(res.data);
        reader.onload = function () {
          let result = { preview: reader.result, isCustom: true };
          setFiles([result]);
        };
      })
      .then(() => {
        setTimeout(() => {
          if (!setting) return;
          const { width, height } = getZoomedWidth();
          const pixelWidth = toPixel(setting.posWidth, width);
          const pixelHeight = toPixel(setting.posHeight, height);
          setPositionDrag({ x: pixelWidth, y: pixelHeight });
        }, 1000);
      })
      .catch(err => {
        const errors = err?.response?.data;
        if (!errors) {
          console.log(errors, 'error');
          return;
        }
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: error, text: message }));
      })
      .finally(dispatch(hideLoaderAction()));
  };
  const deleteImg = id => {
    if (!id) return;
    dispatch(showLoaderAction());
    deleteImageForMinimap(id)
      .then(res => {
        dispatch(
          showSimpleModalAction({
            title: 'Changes saved',
            text: 'Minimap delete successfully',
          })
        );
      })
      .catch(err => {
        const errors = err?.response?.data;
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: error, text: message }));
      })
      .finally(dispatch(hideLoaderAction()));
  };
  const uploadImg = () => {
    const formData = new FormData();
    formData.append('file', files[0]);
    dispatch(showLoaderAction());
    uploadMinimapPhoto(spaceData?.id, floor?.value, formData)
      .then(res => {
        setEdited(false);
        dispatch(
          showSimpleModalAction({
            title: 'Changes saved',
            text: 'Minimap uploaded successfully',
          })
        );
      })
      .catch(err => {
        const errors = err?.response?.data;
        const { error, message, statusCode } = errors;
        dispatch(showSimpleModalAction({ title: error, text: message }));
      })
      .finally(dispatch(hideLoaderAction()));
  };
  const changePosition = (e, value, type) => {
    setPoiMapSetting(prev => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleChangDraggble = (e, core) => {
    setPoiMapSetting(prev => ({
      ...prev,
      posWidth: core.x,
      posHeight: core.y,
    }));
    setPositionDrag({
      x: core.x,
      y: core.y,
    });
  };

  const initialLoad = async () => {
    dispatch(showLoaderAction());
    await loadData('initial');
  };

  useEffect(() => {
    initialLoad();
  }, []);

  return (
    <>
      {isModal && (
        <ModalOrderPlan
          floor={floor}
          setIsModal={setIsModal}
          spaceData={spaceData}
        />
      )}
      <div className="mini-map">
        <h2 className="space-tab__title">{`${
          spaceData?.name || 'Space'
        } - Mini Map`}</h2>
        {switchers.map((elem, index) => {
          return (
            <div className="space-tab__switcher-container" key={index}>
              <div className="space-tab__switcher-text-block">
                <div className="space-tab__switcher-title">{elem.title}</div>
                <div className="space-tab__switcher-text">{elem.text}</div>
              </div>
              <div className="space-tab__switcher">
                <CustomSwitcher checked={isShowMap} onChange={handleSwitch} />
              </div>
            </div>
          );
        })}
        <div className="mini-map__setting">
          <div className="mini-map__floors">
            <span>Choose Floor</span>
            <CustomDropdown
              placeholder="Floor"
              variant="grey"
              value={floor?.name}
              onChange={handleChangeFloor}
              options={options}
              serch={false}
            />
          </div>
          <CustomButton
            name="Order 2D Floorplan"
            disabled={!floor?.name}
            onClick={() => setIsModal(!isModal)}
          />
        </div>
        {
          <>
            <div className="space-tab__sliders_container">
              <div className="space-tab__input-name">Width</div>
              <CustomSlider
                color={color}
                min={0.1}
                max={3}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                step={0.01}
                spanMax={3}
                spanMin={0.1}
                name="width"
                onChange={changePosition}
                value={poiMapSetting.width}
              />
            </div>
            <div className="space-tab__sliders_container">
              <div className="space-tab__input-name">Height</div>
              <CustomSlider
                color={color}
                min={0.1}
                max={3}
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                step={0.01}
                spanMax={3}
                spanMin={0.1}
                name="height"
                onChange={changePosition}
                value={poiMapSetting.height}
              />
            </div>
            <div className="space-tab__sliders_container">
              <div className="tools">
                <div className="tools__back">
                  {/*<button className='tools__button'
                          style={{ backgroundColor: color }}
                          onClick={()=>{
                            setEdited(true)
                            setFiles([])}}>Remove minimap</button>*/}
                  <button
                    className="tools__button"
                    style={{ backgroundColor: color }}
                    onClick={() => {
                      getImgFloor(floor?.name);
                      setIsDeleteImg(true);
                      setEdited(true);
                    }}
                  >
                    Delete minimap
                  </button>
                </div>
              </div>
            </div>
          </>
        }
        {!files?.length ||
          (!files[0]?.isCustom && (
            <div {...getRootProps({ className: 'dropzone' })}>
              <Upload className="dropzone__svg" />
              <input {...getInputProps()} />
              <p className="dropzone__title">Choose files to Upload</p>
              <p className="dropzone__subtitle">or use drag&drop</p>
            </div>
          ))}
        <div className="zoom">
          <div className="zoom-content" ref={zoom}>
            {files[0]?.preview && (
              <>
                <img className="img" src={files[0]?.preview} alt="test" />
                <Draggable
                  handle=".handle"
                  grid={[1, 1]}
                  scale={1}
                  position={positionDrag}
                  onStop={handleChangDraggble}
                >
                  <div className="draggble-img">
                    <div className="mini-map__pois handle">
                      {renderMap?.poi?.map(poi => (
                        <img
                          /*onClick={(e)=>gotToSweep(sweep)}*/
                          className="mini-map__poi"
                          src={poi.icon || defaultIcon}
                          style={{
                            left: `${poi.px.right}`,
                            bottom: `${poi.px.bottom}`,
                          }}
                        ></img>
                      ))}
                      {renderMap?.sweep?.map(sweep => (
                        <span
                          /*onClick={(e)=>gotToSweep(sweep)}*/
                          className="mini-map__sweep"
                          style={{
                            left: `${sweep.px.right}`,
                            bottom: `${sweep.px.bottom}`,
                          }}
                        ></span>
                      ))}
                    </div>
                  </div>
                </Draggable>
              </>
            )}
          </div>
        </div>
        <div className="space-tab__buttons">
          <div className="space-tab__cancel-btn">
            <CustomButton
              name="Cancel"
              variant="grey"
              onClick={e => {
                e.preventDefault();
                if (edited) {
                  setEditModalCansel(prev => !prev);
                } else {
                  closeModule();
                }
              }}
            />
          </div>
          <div className="space-tab__submit-btn">
            <CustomButton
              variant="green"
              name="Save"
              onClick={e => {
                e.preventDefault();
                submitForm();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MiniMap;
