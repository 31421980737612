import React from 'react';
import { useSelector } from 'react-redux';
import './switcher.scss';

export const CustomSwitcher = ({
  checked,
  isTileModal = false,
  onChange,
  ...props
}) => {
  const color = useSelector(state => state.profile.color);

  return (
    <label className="switch">
      <input type="checkbox" checked={checked} onChange={onChange} {...props} />
      <span
        className="slider round"
        style={checked && isTileModal ? { backgroundColor: color } : {}}
      />
    </label>
  );
};
