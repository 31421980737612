import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './space-tabs.scss';
import { ContactTab } from './InfoTabs/ContactTab';
import { TitleAndAddressTab } from './InfoTabs/TitleAndAddressTab';

export const InfoTab = ({
  spaceData,
  setSpaceData,
  edited,
  setEdited,
  handleCancelModal,
  closeModule,
  handleSuccessModal,
}) => {
  const [tab, setTab] = useState('Title and Address');
  const color = useSelector(state => state.profile.color);
  const titleAddressClassnames = `info-tab__tab ${
    tab === 'Title and Address' ? 'info-tab__tab_active' : ''
  }`;
  const contactClassnames = `info-tab__tab ${
    tab === 'Contact' ? 'info-tab__tab_active' : ''
  }`;

  return (
    <div className="space-tab info-tab">
      <h2 className="space-tab__title">{`Edit ${
        spaceData?.name || 'space'
      }`}</h2>

      <div className="info-tab__tabs">
        <div
          className={titleAddressClassnames}
          onClick={() => setTab('Title and Address')}
          style={
            titleAddressClassnames.includes('active')
              ? { border: `1px solid ${color}`, color }
              : {}
          }
        >
          Title and Address
        </div>
        <div
          className={contactClassnames}
          onClick={() => setTab('Contact')}
          style={
            contactClassnames.includes('active')
              ? { border: `1px solid ${color}`, color }
              : {}
          }
        >
          Contact
        </div>
      </div>

      {tab === 'Title and Address' ? (
        <TitleAndAddressTab
          spaceData={spaceData}
          setSpaceData={setSpaceData}
          edited={edited}
          setEdited={setEdited}
          handleCancelModal={handleCancelModal}
          closeModule={closeModule}
          handleSuccessModal={handleSuccessModal}
        />
      ) : (
        <ContactTab
          spaceData={spaceData}
          setSpaceData={setSpaceData}
          edited={edited}
          setEdited={setEdited}
          handleCancelModal={handleCancelModal}
          closeModule={closeModule}
          handleSuccessModal={handleSuccessModal}
        />
      )}
    </div>
  );
};
