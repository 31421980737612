import React, { useState } from 'react'
import { CustomModal } from '../../../../components/CustomModal/CustomModal'
import { CustomDropdown } from '../../../../components/CustomDropdown/CustomDropdown'
import { onFocusOut, onInputChange } from '../../../../helpers/formUtils'
import { CustomInput } from '../../../../components/CustomInput/CustomInput'
import { SimpleInput } from '../../../../components/CustomInput/SimpleInput'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'

const ModalTileVideoUrl = ({data,submit,close}) => {
  const [inputField, setInputField] = useState(data)
  return (
    <div className='tile-modal-type tile-modal-video'>
        <CustomModal
          title="Tile Menu - Tile - Video URL"
          close={close}
          submit={()=>submit(inputField)}
          cancelBtn="Cancel"
          submitBtn="Save"       >
          <div className='tile-modal--subtitle'>Video URL</div>
          <SimpleInput
            placeholder='Video URL'
            value={inputField}
            onChange={(e)=>setInputField(e.target.value)}/>
        </CustomModal>
    </div>
  )
}

export default ModalTileVideoUrl