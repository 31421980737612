import React, { useState, useReducer, useEffect } from 'react';
import './forgot-password.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import {
  showLoaderAction,
  hideLoaderAction,
  setConfigsByDomain,
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Check } from '../../assets/icons/check-green.svg';
import { forgotPassword } from '../../crud/auth';
import { findByDomainCustomization } from '../../crud/customize';
import {
  onInputChange,
  onFocusOut,
  formsReducer,
  validateForm,
  updateFormAction,
} from '../../helpers/formUtils';

const initialState = {
  email: { value: '', touched: false, hasError: true, error: '' },
  isFormValid: false,
};

export const ForgotPassword = () => {
  const [formData, setFormData] = useReducer(formsReducer, initialState);
  const [messageIsSent, setMessageIsSent] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const logo = useSelector(state => state.profile.logo);
  const color = useSelector(state => state.profile.color);

  const handleSubmit = e => {
    if (e) e.preventDefault();
    if (validateForm(formData, setFormData)) {
      dispatch(showLoaderAction());
      forgotPassword(formData.email.value)
        .then(() => {
          setMessageIsSent(true);
          dispatch(hideLoaderAction());
        })
        .catch(err => {
          let message = err?.response?.data?.message;
          if (message === 'email not found') {
            setFormData(
              updateFormAction({
                ...formData.email,
                name: 'email',
                touched: true,
                hasError: true,
                error: 'Email not found',
                isFormValid: false,
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    findByDomainCustomization(
      new URL(document.URL).hostname
      // 'development.spadmin.akrotonx.com'
    ).then(res => {
      dispatch(setConfigsByDomain(res.data));
    });
  }, []);

  return (
    <div className="reset-password-page">
      <div className="main-block">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <form className="form">
          <div className="form__head flex-sb">
            <h4>Reset Password</h4>
          </div>

          {messageIsSent ? (
            <div className="form__main-container">
              <div
                className="form__img-wrapper"
                style={{ border: `4px solid ${color}` }}
              >
                <Check
                  alt="success"
                  className="form__success-img"
                  fill={color}
                />
              </div>
              <div className="form__success-text">
                Check your Email. The password has been sent to you
              </div>
            </div>
          ) : (
            <>
              <div className="form__input">
                <CustomInput
                  type="email"
                  placeholder={`Email ${t('address')}`}
                  formData={formData.email}
                  onChange={e =>
                    onInputChange(
                      'email',
                      e.target.value,
                      setFormData,
                      formData
                    )
                  }
                  onBlur={e =>
                    onFocusOut('email', e.target.value, setFormData, formData)
                  }
                />
              </div>

              <div className="form__button">
                <CustomButton
                  type="submit"
                  variant="green"
                  name={'Send Password'}
                  onClick={handleSubmit}
                />
              </div>
            </>
          )}

          <div className="form__bottom-text">
            <Link to="/login" className="form__link">
              <span className="form__arrow">{'<'}</span>
              <span>Back to Login</span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};
