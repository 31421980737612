import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './space-tabs.scss';
import { Categories } from './AccessSettings/Categories';
import FrontendUsers from './AccessSettings/FrontendUsers';
import { ReactComponent as Plus } from '../../../../assets/icons/plus.svg';

const AccessSettings = ({
  spaceData,
  setSpaceData,
  edited,
  setEdited,
  handleCancelModal,
  closeModule,
  handleSuccessModal,
}) => {
  const [tab, setTab] = useState('User');
  const [addNew, setAddNew] = useState('');
  const color = useSelector(state => state.profile.color);
  const userClassnames = `info-tab__tab ${
    tab === 'User' ? 'info-tab__tab_active' : ''
  }`;
  const categoryClassnames = `info-tab__tab ${
    tab === 'Category' ? 'info-tab__tab_active' : ''
  }`;

  const handleCreate = e => {
    e.preventDefault();
    if (tab === 'User') {
      setAddNew('User');
    } else {
      setAddNew('Category');
    }
  };

  return (
    <div className="space-tab info-tab">
      <h2 className="space-tab__title">{`Edit ${
        spaceData?.name || 'space'
      }`}</h2>

      <div className="info-tab__tabs access-settings__tabs">
        <div className="access-settings__tab">
          <div
            className={userClassnames}
            onClick={() => setTab('User')}
            style={
              userClassnames.includes('active')
                ? { border: `1px solid ${color}`, color }
                : {}
            }
          >
            Frontend Users
          </div>
          <div
            className={categoryClassnames}
            onClick={() => setTab('Category')}
            style={
              categoryClassnames.includes('active')
                ? { border: `1px solid ${color}`, color }
                : {}
            }
          >
            Categories
          </div>
        </div>
        <div className="access-settings__button">
          <span> Add {tab}</span>
          <button onClick={handleCreate} className="access-settings__btn">
            <Plus fill={color} />
          </button>
        </div>
      </div>

      {tab === 'User' ? (
        <FrontendUsers
          spaceData={spaceData}
          addNew={addNew}
          setAddNew={setAddNew}
        />
      ) : (
        <Categories
          spaceData={spaceData}
          addNew={addNew}
          setAddNew={setAddNew}
        />
      )}
    </div>
  );
};

export default AccessSettings;
